import React from "react";
import { getAllSwag } from '../../data/DL';
import ProductCard from "../shared/product_card";
import { List, Typography } from "antd";
import Spinner from "../shared/Spinner";

const { Title } = Typography;

export default class Shop extends React.Component {
  state = {
    items: [],
    error: false
  }

  async componentWillMount() {
    const items = await getAllSwag();
    this.setState({ items, error: !items });
  }

  render() {
    const { items, error } = this.state;
    
    return (
      <div style={styles.body}>
        {/* <Title style={{ color: '#B1736C'}}>The</Title>
        <Title style={{ color: '#B1736C', paddingBottom: 25 }}>Store</Title> */}
        <div style={styles.header}>
          <Title style={{ color: '#B1736C', marginBottom: 5 }}>Shop</Title>
          <Title style={{ color: '#E9D3C5', marginTop: 5 }}>your elemnt</Title>
        </div>

        {
          items && items.length > 0 ?
            <List
              dataSource={items}
              // itemLayout="horizontal"
              renderItem={product => 
                <List.Item>
                  <ProductCard product={product} showCart showInfo />
                </List.Item>
              }
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 3,
                xxl: 3,
              }}
            />
            : error ? (
              <h1>An unknown error has occurred.</h1>
            ) 
            : (
              <Spinner />
            )
        }
      </div>
    );
  }
}


const styles = {
  body: {
    background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '6vw'
  },
  header: {
    marginBottom: '75px'
  }
};