import React from "react";
import { Typography, List, Badge, Button } from 'antd';
import { connect } from "react-redux";
import Spinner from "../shared/Spinner";
import { getOrderById, getMixById, getSwagById } from "../../data/DL";
import { sendUnsubscribeReq } from "../../redux/actions/subscription";
import ProductCard from '../shared/product_card';
import { Redirect } from 'react-router-dom';

const { Title, Text } = Typography;

class OrderPage extends React.Component {
  state = {
    order: undefined,
    error: false
  };

  async componentWillMount() {
    const { id } = this.props.match.params;
    const order = await getOrderById(id);

    const products = await Promise.all(
      order.products.map(({ pid , isMix }) => 
        isMix ? getMixById(pid) : getSwagById(pid)
      )
    );

    Object.assign(order, { products });
    this.setState({ order, error: order === undefined });
  }

  render() {
    const { order, error } = this.state;

    return (
      <div style={styles.body}>
        {order ? (
          <OrderView 
            order={order} 
            unsubscribe={this.props.sendUnsubscribeReq} 
            isLoading={
              this.props.subscription && this.props.subscription.isLoading
            }
          />
        ) : error ? (
          <h1>An unknown error has occurred.</h1>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

class OrderView extends React.Component {
  state = {
    complete: false
  };
  
  render() {
    const { order, unsubscribe, isLoading } = this.props;
    const { complete } = this.state;

    const { 
      name,
      address, 
      shipped, 
      date, 
      amount, 
      products, 
      oid, 
      subscription 
    } = order;

    if (complete) {
      return <Redirect to='/account' />;
    }
    
    return (
      <>
        <div style={styles.section}>
          <Title>Order</Title>
          <Title 
            style={{ marginTop: 5 }}
            level={4}
          >
            #{oid}
          </Title>
          <Text style={styles.secondary}>
            Created {date}
          </Text>
          <Text style={styles.secondary}>
            {name}
          </Text>
          <br />
          <Badge
            status={shipped ? "success" : "processing"}
            text={<Text style={styles.secondary}>{shipped ? "Shipped" : "In progress"}</Text>}
          />
          <br /><br />

          {subscription && (
          <>
            <Text style={{ marginTop: "15px" }}>
              This order is a subscription.
            </Text>
            <Button
              loading={isLoading}
              onClick={() => {
                unsubscribe({ oid }).then(() =>  
                  this.setState({ complete: true })
                );
              }}
              style={{ color: "#B1736C" }}
              type="link"
              className='subscribeButton'
            >
              <Text style={{ color: "#B1736C" }}>Unsubscribe?</Text>
            </Button>
            <br />
          </>
        )}
          <Text style={{ marginTop: '15px' }}>
            <a 
              href={
                `mailto:inmyelemnt@gmail.com?subject=Question re:order #${oid}`
              }
            >
              I have an issue or question regarding this order.
            </a>
          </Text>
        </div>

        <div style={styles.section}>
          <Title style={{ color: '#B1736C' }}>Products</Title>
          <Text style={styles.secondary}>
            ${amount} billed
          </Text>

          <List
            dataSource={products}
            style={{ marginTop: 25 }}
            // itemLayout="horizontal"
            renderItem={product => 
              <List.Item>
                <ProductCard 
                  product={product} 
                  isMix={product.base && typeof product.base === 'string'}
                  showCart
                  showInfo
                />
              </List.Item>
            }
            grid={{
              gutter: 400,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 3,
              xxl: 3,
            }}
          />
        </div>
        
        <div style={styles.section}>
          <Title style={{ color: '#B1736C' }}>Shipping Address</Title>

          <Text style={styles.secondary}>{address.name}</Text>
          <br />

          <Text style={styles.secondary}>{address.addressLineOne}</Text>
          <br />

          {address.addressLineTwo && (
            <>
              <Text style={styles.secondary}>{address.addressLineTwo}</Text>
              <br />
            </>
          )}

          <Text style={styles.secondary}>{address.city}, </Text>
          <Text style={styles.secondary}>{address.region} </Text>
          <Text style={styles.secondary}>{address.zip}</Text>
          <br />

          <Text style={styles.secondary}>{address.country}</Text>
          <br />

          {address.phone && (
            <>
              <Text style={styles.secondary}>{address.phone}</Text>
              <br />
            </>
          )}
        </div>
      </>
    );
  }
}

const styles = {
  body: {
    background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '6vw'
  },
  card: {
    width: "300px",
    height: "300px"
  },
  section: {
    marginBottom: '75px',
    width: '90%',
    maxWidth: '750px'
  },
  secondary: {
    color: '#E9D3C5', 
    fontSize: 18,
    marginTop: 5,
  }
};

const mapDispatchToProps = {
  getOrderById,
  sendUnsubscribeReq,
};

const mapStateToProps = state => ({
  subscription: state.subscription
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPage);
