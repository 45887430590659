export const ADD_TO_CART = "[CART] Add to cart";
export const addProductToCart = ({ pid, isMix }) => ({
  type: ADD_TO_CART,
  payload: {
    pid,
    isMix
  }
});

export const REMOVE_FROM_CART = "[CART] Remove from cart";
export const removeProductFromCart = ({ pid }) => ({
  type: REMOVE_FROM_CART,
  payload: {
    pid
  }
});

export const CLEAR_CART = "[CART] Clear cart";
export const clearCart = () => ({
  type: CLEAR_CART
});
