// TODO: onChange for each field => setState in redux
// TODO: make placeholders show user info

import "../../index.css";
import React from "react";
import {
  Menu,
  Icon,
  Layout,
  Typography,
  Tag,
  Button,
  List,
  message,
  Descriptions,
  Badge
} from "antd";

import { Link, Redirect } from "react-router-dom";

import "firebase/auth";
import { connect } from "react-redux";
import { logout, updateUserData } from "../../redux/actions/user";
import { getMixById } from "../../data/DL";
import ProductCard from "../shared/product_card";
import CheckoutInputs from "../shared/CheckoutInputs";
import { sendUnsubscribeReq } from "../../redux/actions/subscription";
import { refetchUser } from "../../redux/actions/user";

import Spinner from "../shared/Spinner";

const { SubMenu } = Menu;
const { Sider, Content } = Layout;
const { Title, Text } = Typography;

class Account extends React.Component {
  state = {
    key: 1,
    recommendation: undefined,
    isLoading: false
  };

  async componentDidMount() {
    const { user } = this.props.user;

    if (!user) { return; }

    this.setState({ isLoading: true });

    const recommendation =
      user && user.recommendation
        ? await getMixById(user.recommendation)
        : undefined;

    this.setState({ isLoading: false, recommendation }, this.props.refetchUser(user.uid));
  }

  handleClick = ({ key }) => {
    this.setState({ key });
  };

  basicInfo = () => {
    const { updateUserData } = this.props;
    const { user } = this.props.user;

    return (
      <div>
        <CheckoutInputs
          onSubmit={values =>
            updateUserData({ uid: user.uid, data: { address: values } })
              .then(() => message.success("Done!"))
              .catch(() => message.error("An error occurred."))
          }
        />
      </div>
    );
  };

  recommended = () => {
    const { user } = this.props.user;
    const { recommendation } = this.state;

    return (
      <>
        <Title level={2} style={{ ...styles.header, color: "#B1736C" }}>
          Quiz
        </Title>
        {user && user.quizzed ? (
          <>
            <Tag color="#B1736C" style={{ marginRight: "15px" }}>
              <Icon type="check-circle" />
              <Text style={{ color: "white", marginLeft: "5px" }}>
                complete
              </Text>
            </Tag>
            <Text>
              <Link to="/quiz">Retake?</Link>
            </Text>
          </>
        ) : (
          <>
            <Tag color="#E9D3C5" style={{ marginRight: "15px" }}>
              <Icon type="close-circle" />
              <Text style={{ color: "white", marginLeft: "5px" }}>
                incomplete
              </Text>
            </Tag>
            <Text>
              <Link to="/quiz">Take the quiz!</Link>
            </Text>
          </>
        )}

        <div style={styles.section}>
          <Title level={2} style={{ ...styles.header, color: "#B1736C" }}>
            Your Elemnt
          </Title>

          {recommendation ? (
            <ProductCard product={recommendation} isMix showInfo showCart />
          ) : (
            <>
              <Text bold>No recommendation available.</Text>
              <br />
              <Link
                to="/quiz"
                style={{ color: "#B1736C", textDecoration: "underline" }}
              >
                Take the quiz!
              </Link>
            </>
          )}
        </div>
      </>
    );
  };

  orders = () => {
    const { user } = this.props.user;

    const data =
      user && user.orders
        ? user.orders.sort((a, b) => new Date(b.date) - new Date(a.date))
        : [];

    return (
      <>
        <Title level={2} style={{ ...styles.header, color: "#B1736C" }}>
          Orders
        </Title>
        <List
          style={styles.orders}
          itemLayout="vertical"
          dataSource={data}
          renderItem={order => (
            <div style={{ marginBottom: "25px" }}>
              <OrderItem
                order={order}
                unsubscribe={this.props.sendUnsubscribeReq}
                {...this.props}
              />
            </div>
          )}
        />
      </>
    );
  };

  render() {
    const viewsForKey = {
      "1": this.recommended,
      "2": this.basicInfo,
      "3": this.orders
    };

    const { user } = this.props;
    const { isLoading } = this.state;
    
    if (isLoading || user.isLoading) {
      return <Spinner />;
    }

    if (!user.isLoggedIn) {
      return <Redirect to='/' />;
    }

    return (
      <Layout style={{ background: "white" }}>
        <Content className="accountBody" style={styles.body}>
          <div style={styles.container}>
            <Sider
              theme="light"
              breakpoint="lg"
              collapsedWidth="0"
              style={{ background: "transparent" }}
            >
              <SideMenu
                handleClick={this.handleClick}
                logout={this.props.logout}
              />
            </Sider>
          </div>

          <div style={styles.container}>{viewsForKey[this.state.key]()}</div>
        </Content>
      </Layout>
    );
  }
}

class SideMenu extends React.Component {
  render() {
    return (
      <Menu
        onClick={this.props.handleClick}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["profile"]}
        mode="inline"
        style={styles.menu}
      >
        <SubMenu
          key="profile"
          title={
            <span>
              <Icon type="user" />
              <span>Profile</span>
            </span>
          }
        >
          <Menu.Item key="1">Recommended</Menu.Item>
          <Menu.Item key="2">Basic Info</Menu.Item>
        </SubMenu>
        <Menu.Item key="3">Orders</Menu.Item>
        <Menu.Item>
          <Text>
            <Link
              onClick={() => {
                this.props.logout();
              }}
              style={{ fontSize: "14px" }}
            >
              Logout
            </Link>
          </Text>
        </Menu.Item>
      </Menu>
    );
  }
}

class OrderItem extends React.Component {
  render() {
    const {
      products,
      date,
      amount,
      shipped,
      address,
      oid,
      subscription
    } = this.props.order;

    const { unsubscribe } = this.props;
    const { isLoading } = this.props.subscription;

    return (
      <>
        <Descriptions
          layout="vertical"
          bordered
          column={{
            // gutter: 400,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 2,
            xxl: 2
          }}
        >
          <Descriptions.Item label="ID">
            <Link
              to={`/orders/${oid}`}
              style={{ color: "#B1736C", textDecoration: "underline" }}
            >
              {oid}
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label="Total">${amount}</Descriptions.Item>

          <Descriptions.Item label="Date">{date}</Descriptions.Item>

          <Descriptions.Item label="Status">
            <Badge
              status={shipped ? "success" : "processing"}
              text={
                <span style={{ color: "#B1736C" }}>
                  {shipped ? "Shipped" : "In progress"}
                </span>
              }
            />
          </Descriptions.Item>

          {/* <Descriptions.Item label="Product #'s">
            <List
              itemLayout="vertical"
              dataSource={products}
              renderItem={({pid, isMix}, i) => (
                <>
                  <span style={{ color: "#B1736C" }}>{i + 1}. </span>
                  <Link
                    to={`/${isMix ? 'mixes' : 'swag'}/${pid}`}
                    style={{ color: "#B1736C", textDecoration: "underline" }}
                  >
                    {pid}
                  </Link>
                  <br />
                </>
              )}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Address">
            {address.name}
            <br />

            {address.addressLineOne}
            <br />

            {address.addressLineTwo && (
              <>
                {address.addressLineTwo}
                <br />
              </>
            )}

            {address.city}, 
            {address.region} 
            {address.zip}
            <br />

            {address.country}
            <br />

            {address.phone && (
              <>
                {address.phone}
                <br />
              </>
            )}
          </Descriptions.Item> */}
        </Descriptions>

        {subscription && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ paddingTop: 5 }}>
              This order is a subscription.&nbsp;
            </Text>
            <Button
              loading={isLoading}
              onClick={() => {
                unsubscribe({ oid });
              }}
              style={{ color: "#B1736C", padding: 0, margin: 0 }}
              type="link"
              className='subscribeButton'
            >
              <Text style={{ color: "#B1736C", padding: 0, margin: 0 }}>
                Unsubscribe?
              </Text>
            </Button>
          </div>
        )}

        {/* <br /> */}
        <Text style={{ marginTop: "10px", marginBottom: "10px" }}>
          <a
            href={`mailto:inmyelemnt@gmail.com?subject=Question re:order #${oid}`}
          >
            I have an issue or question regarding this order.
          </a>
        </Text>
      </>
    );
  }
}

const styles = {
  body: {
    background: "rgb(246,	237,	223, 0.5)", // '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: "85vh",
    width: "90vw",
    margin: "2.5vh 5vw 2.5vh 5vw",
    padding: "2vw",
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "start",
    overflow: "visible"
  },

  container: {
    minHeight: 50,
    margin: "1.5vh 3vw 1.5vh 3vw"
    // minWidth: '256px'
  },

  menu: {
    // width: "256px",
    // paddingTop: "15px",
    // paddingBottom: "25px",
    marginTop: "5px",
    border: "5px solid #B1736C"
  },

  section: {
    marginTop: "50px",
    clear: "right"
  },

  icon: {
    color: "rgba(0,0,0,.25)"
  },

  input: {
    marginBottom: "15px"
  },

  orders: {},

  header: {
    marginBottom: "25px"
  }
};

const mapDispatchToProps = {
  logout,
  updateUserData,
  sendUnsubscribeReq,
  refetchUser
};

const mapStateToProps = state => ({
  user: state.user,
  subscription: state.subscription
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
