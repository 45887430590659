import React from "react";
import { Spin, Icon } from "antd";

export default function Spinner(props) {
  return (
    <div style={styles.spinnerStyle}>
      <Spin 
        size="large" 
        indicator={
          <Icon 
            type="loading" 
            style={{ fontSize: 48, color: "#B1736C" }} 
            spin 
          />
        } 
      />
    </div>
  );
}

const styles = {
  spinnerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    marginBottom: "20px",
    padding: "30px 50px",
    margin: "20px 0",
    backgroundColor: 'transparent'
  }
};
