import { unsubscribe } from "../../data/DL";
import { refetchUser } from "./user";
import { push } from "connected-react-router";
import { message } from 'antd';

export const UNSUBSCRIBE_REQ_BEGIN = "[UNSUBSCRIBE] Unsubscribe begin";
export const setUnsubscribeBegin = () => ({
  type: UNSUBSCRIBE_REQ_BEGIN
});

export const UNSUBSCRIBE_REQ_SUCCESS = "[UNSUBSCRIBE] Unsubscribe success";
export const setUnsubscribeSuccess = data => ({
  type: UNSUBSCRIBE_REQ_SUCCESS,
  payload: data
});

export const UNSUBSCRIBE_REQ_FAILED = "[UNSUBSCRIBE] Unsubscribe failed";
export const setUnsubscribeFailed = data => ({
  type: UNSUBSCRIBE_REQ_FAILED,
  payload: data
});

export const sendUnsubscribeReq = ({ oid }) => (dispatch, getState) => {
  dispatch(setUnsubscribeBegin());

  return unsubscribe({ oid })
    .then(res => {
      message.success('Done!')
      
      dispatch(setUnsubscribeSuccess(res));
      dispatch(refetchUser(getState().user.user.uid));
    })
    .catch(error => {
      message.error('An error occurred.')
      dispatch(setUnsubscribeFailed(error));
    });
};
