import {
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  USER_LOGOUT,
  USER_LOGOUT_FAIL,
  SET_AUTH_LOADING,
  UPDATE_USER_DATA_FAIL,
  UPDATE_USER_DATA_SUCCESS
} from "../actions/user";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  user: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      });
    case UPDATE_USER_DATA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: Object.assign({}, state.user, action.payload)
      });
    case LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.payload,
        isLoggedIn: true,
        error: null
      });
    case CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.payload,
        isLoggedIn: true,
        error: null
      });
    case CREATE_USER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        user: null,
        isLoggedIn: false,
        error: action.payload.message
      });
    case LOGIN_USER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        user: null,
        isLoggedIn: false,
        error: action.payload.message
      });
    case USER_LOGOUT:
      return Object.assign({}, state, {
        isLoading: false,
        user: null,
        isLoggedIn: false,
        error: null
      });
    case USER_LOGOUT_FAIL:
      return state;
    case SET_AUTH_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      });
    default:
      return state;
  }
};
