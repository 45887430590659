import '../../index.css';
import React from "react";
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

export default class FooterBar extends React.Component {
  render() {
    return (
      <div className="footerBody" style={styles.body}>
        <div style={styles.section}>
          <Text strong style={styles.header}>MY elemnt</Text>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <Text>
                <Link 
                  to='/quiz'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  QUIZ
                </Link>
              </Text>
            </li>

            <li style={styles.listItem}>
              <Text>
                <Link 
                  to='/shop'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  SHOP
                </Link>
              </Text>
            </li>

            <li style={styles.listItem}>
              <Text>
                <Link 
                  to='/account'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  ACCOUNT
                </Link>
              </Text>
            </li>
          </ul>
        </div>

        <div style={styles.section}>
          <Text strong style={styles.header}>SUPPORT</Text>

          <ul style={styles.list}>
            <li style={styles.listItem}>
              <Text>
                <Link 
                  to='/about'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  ABOUT
                </Link>
              </Text>
            </li>

            <li style={styles.listItem}>
              <Text>
                <a 
                  href='mailto:inmyelemnt@gmail.com'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  CONTACT
                </a>
              </Text>
            </li>
          </ul>
        </div>

        <div style={styles.section}>
          <Text strong style={styles.header}>@SOCIAL</Text>

          <ul style={styles.list}>
            <li style={styles.listItem}>
              <Text>
                <a 
                  href='https://www.instagram.com/myelemnt/'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  INSTAGRAM
                </a>
              </Text>
            </li>

            <li style={styles.listItem}>
              <Text>
                <a 
                  href='https://www.facebook.com/Elemnt-2331755667081723/'
                  style={{ color: 'rgb(246,	237,	223, 0.5)' }}
                  className='footerLink'
                >
                  FACEBOOK
                </a>
              </Text>
            </li>
          </ul>
        </div>

        <div style={styles.section}>
          <Text strong style={styles.header}>DISCLAIMER</Text>
          
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <Text style={{ marginTop: '25px', color: '#E9D3C5' }}>
                These statements have not been evaluated by the 
                Food and Drug Administration. This product is not intended 
                to diagnose, treat, cure or prevent any disease.
              </Text>
            </li>

            <li style={styles.listItem}>
              <Text style={{ marginTop: '25px', color: '#E9D3C5' }}>
                © 2019 elemnt LLC. All rights reserved.
              </Text>
            </li>
          </ul>
        </div>
      </div>
    );
  }
};

const styles = {
  body: {
    background: '#B1736C',//'rgb(246,	237,	223, 0.5)',	// '#F7EDDD' #B1736C,
    // border: '5px solid #B1736C',
    minHeight: '10vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '65px',
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'center',
  },

  section: {
    border: '5px solid rgb(246,	237,	223, 0.5)',
    margin: '25px',
    minWidth: '175px',
    padding: '25px'
  },

  header: {
    marginBottom: '25px',
    color: '#E9D3C5'
  },

  list: { 
    padding: 0, 
    marginTop: '25px', 
    color: 'rgb(246,	237,	223, 0.5)', 
    listStyle: 'none' 
  },

  listItem: {
    marginBottom: '15px'
  }
};