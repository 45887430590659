export const SET_QUIZ_STEP = "[QUIZ] Set Quiz Step";
export const REFRESH_QUIZ_STEP = "[QUIZ] Refresh Quiz Step";

export const setQuizStep = ({ step, value }) => ({
  type: SET_QUIZ_STEP,
  payload: {
    step,
    value
  }
});

export const refreshQuiz = () => ({
  type: REFRESH_QUIZ_STEP
});
