import "../../index.css";
import React from "react";
import { connect } from "react-redux";
import { Card, Tag, Typography, Icon, Button, Popover, message } from "antd";
import { Link } from "react-router-dom";
import {
  addProductToCart,
  removeProductFromCart
} from "../../redux/actions/cart";
import ingredients from "../../data/ingredients";
import nutrition from "../../data/nutrition";
import milks from "../../data/milks";
import bases from "../../data/bases";

const { Title, Text } = Typography;

class NutritionCard extends React.Component {
  state = {
    visible: false
  };

  async display() {
    this.setState({ visible: true });
  }

  addToCart() {
    const { product, isMix } = this.props;
    this.props.addProductToCart({ pid: product.id, isMix });
    message.success('Added to cart!');
  }

  removeFromCart() {
    const { product, removeProductFromCart } = this.props;
    removeProductFromCart({ pid: product.id });
  }

  setStyle(percent, num) {
      if(percent === - 1) {
          return <Text className="txtr" style={styles.txtRight}>{("")}</Text>
      } else if(percent === -2) {
        return <Text className="txtr" style={{fontSize: "2.5em",
        float: "right", marginTop: "-0.25em"}}>{("†")}</Text>
      } else {
        return <Text className="txtr" style={styles.txtRight}>{(Math.ceil(percent * num) + "%")}</Text>
      }
  }

  render() {
    const {
      product,
      isMix,
      showInfo,
      showCart,
      showRemove,
      showQuantity
    } = this.props;

    return (
      <>
          <div className="nutCard" style={styles.content}>
            {isMix && (
              <>
              <div className="hoe" style={styles.grid}>
                <h1>Supplement Facts</h1>
                <p className="scoop" style={styles.scoop}>Serving Size 1 Bottle {product.variant.toLowerCase() === 'lose' ? '67g' : product.variant.toLowerCase() === 'maintain' ? '73.5g' : '83.5g'}</p>
                <hr className="tophr" style={styles.hrr}></hr>
                <div style={{maxHeight: "40px", width: "100%"}}>
                    <p className="a1" style={{fontSize: "8px", fontWeight: "bold", marginLeft: "5%"}}>Amount Per <br></br>Serving in <br></br>{milks[product.variant.toLowerCase()] }</p>
                    <p className="d1" style={{fontSize: "8px", fontWeight: "bold", marginLeft: "40%", marginTop: "-5%"}}>% Daily <br></br>Value</p>
                    <p className="a2" style={{fontSize: "8px", fontWeight: "bold", marginLeft: "55.5%", marginTop: "-6%"}}>Amount Per <br></br>Serving in <br></br>{milks[product.variant.toLowerCase()] }</p>
                    <p className="d2" style={{fontSize: "8px", fontWeight: "bold", marginLeft: "91%", marginTop: "-5%"}}>% Daily <br></br>Value</p>
                </div>
                <hr className="midhr" style={{height: "4px", backgroundColor: "black",}}></hr>
                <ul className="ulnutcard" style={{paddingLeft: 0, marginBottom: "25px", columns: '2'}}>
                    {Object.keys(nutrition[product.variant.toUpperCase()]).map((nut, index) => {
                        const info = nutrition[product.variant.toUpperCase()];
                        const keyz = Object.keys(info);
                        let num = 1;

                        if(index >= 14 && index < 31) {
                            if(product.variant.toUpperCase() === 'LOSE') {
                                num = 0.2;
                            } else if(product.variant.toUpperCase() === 'MAINTAIN') {
                                num = 0.22;
                            } else {
                                num = 0.25;
                            }
                        } else if(index >= 31) {
                            num = 1;
                        }
                        
                            return (
                            <div style={styles.fContainer}>
                                <li className="holo" style={styles.yolo} key={index}>
                                    <Text className="txtl" style={styles.txt}>{info[keyz[index]]["name"] + "        " + (Math.round(info[keyz[index]]["val"] * num * 100) / 100) +  " " + info[keyz[index]]["unit"]}</Text>
                                    {this.setStyle(info[keyz[index]]["percent"], num)}
                                    <hr className="lihr" style={{height: "3px", backgroundColor: "black",}}></hr>
                                </li>
                            </div>
                        )
                    })}

                </ul>
                <div style={styles.nutFooter}>
                    <br></br><strong>OTHER INGREDIENTS:</strong> Maltodextrin, Protein Blend (Whey Protein Concentrate, Calcium Caseinate, Egg Albumen, Sweet Dairy Whey), Cocoa (Processed with Alkali), Natural and Artificial Flavors, Vitamin and Mineral Blend (Dipotassium Phosphate, Magnesium Aspartate, Dicalcium Phosphate, Calcium Citrate, Ascorbic Acid, Niacinamide, Zinc Citrate, Beta Carotene, d-Calcium Pantothenate, d-alpha Tocopheryl Succinate, Selenomethionine, Copper Gluconate, Ferrous Fumarate, Manganese Amino Acid Chelate, Thiamin Mononitrate, Pyridoxine Hydrochloride, Riboflavin, Chromium Polynicotinate, Folic Acid, Biotin, Molybdenum Amino Acid Chelate, Cholecalciferol, Potassium Iodide, Cyanacobalamin), Lecithin, Acesultame Potassium, Medium Chain Triglycerides.
                    <br></br><br></br>
<strong>ALLERGEN INFORMATION:</strong> CONTAINS MILK, EGG, WHEAT (GLUTAMINE PEPTIDES) AND SOY (LECITHIN) INGREDIENTS.</div>
              </div>
                {/* <div style={styles.grid}>
                <ul style={{ paddingLeft: 0, marginBottom: "25px" }}>
                  {Object.keys(nutrition[product.variant.toUpperCase()]).map((nut, index) => {

                      const info = nutrition[product.variant.toUpperCase()];
                      const keyz = Object.keys(info);
                      let num = 1;

                      if(index >= 14) {
                          if(product.variant.toUpperCase() === 'LOSE') {
                              num = 0.2;
                          } else if(product.variant.toUpperCase() === 'MAINTAIN') {
                              num = 0.22;
                          } else {
                              num = 0.25;
                          }
                      }
                      console.log(index);
                      console.log(num);

                    return (
                      <li style={styles.gridcomp} key={index}>
                        <Popover
                          content={
                            <Text>{"Amount per serving: " + (info[keyz[index]]["val"] * num).toFixed(2) +  info[keyz[index]]["unit"] + " |" + (info[keyz[index]]["percent"] === -1 ? " % Daily Value" : (info[keyz[index]]["percent"] * num).toFixed(2) + " % Daily Value ")}</Text>
                          }
                          trigger="hover"
                        >
                          <Tag color={"#B1736C"}>
                            <Text style={{ color: "white" }}>{info[keyz[index]]["name"]}</Text>
                          </Tag>
                        </Popover>
                      </li>
                    );
                  })}
                </ul>
                </div> */}

              </>
            )}

            {/* end of function */}

            {!isMix && (
              <>
                <div style={styles.photo} />
                {/* <Text>
                  {product.description.split('\\n').join(' · ')}
                </Text>

                <ul style={{ padding: 15, color: '#B1736C' }}>
                  {
                    product.description.split('\\n')
                      .map((descript) => 
                        <li><Text>{descript}</Text></li>
                      )
                  }
                </ul> */}
              </>
            )}
          </div>
      </>
    );
  }
}

const styles = {
  fContainer: {
    width: '50%'
  },
  secContainer: {
    width: '50%'
  },
  txt: {
    fontSize: "1em"
  },
  txtRight: {
    fontSize: "1em",
    float: "right"
  },
  hrr: {
    height: "10px",
    backgroundColor: "black",
    display: "block",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginLeft: "auto",
    marginRight: "auto",
    borderStyle: "inset",
    borderWidth: "1px",
  },
  yolo: {
    textAlign: 'left',
    listStyleType: "none",
    display: 'table',
    marginLeft: '30px',
    fontSize: "8px",
    width: "275px"
  },
  yolo2: {
    textAlign: 'left',
    listStyleType: "none",
    display: 'column',
    marginLeft: '45vh',
    fontSize: "8px",
    width: "275px"
  },
  grid: {
    borderStyle: "solid",
    float: 'left',
    padding: '10px',
    maxHeight: '1000px',
    height: '700px',
    width: '700px'
  },
  card: {
    width: "700px",
    height: "470px",
    // backgroundColor: '#E9D3C5',
    borderRadius: 0
  },
  ingredient: {
    color: "#B1736C",
    fontWeight: "bold",
    listStyleType: "none",
    marginBottom: "10px"
  },
  photo: {
    width: "245px",
    height: "225px",
    background: "rgba(246, 237, 220, 0.5)",
    // margin: "0 auto",
    marginBottom: "5px"
  },
  content: {
    height: "100px",
    width: '260px'
  },
  button: {
    color: "#B1736C",
    fontSize: "14px",
    fontWeight: 500
  },
  scoop: {
      fontSize: "12px"
  },
  nutFooter: {
      fontSize: "6px",
      borderTop: "2px solid black",
      width: '100%'
  }
};

const mapDispatchToProps = {
  addProductToCart,
  removeProductFromCart
};

export default connect(
  null,
  mapDispatchToProps
)(NutritionCard);
