import { SET_QUIZ_STEP, REFRESH_QUIZ_STEP } from "../actions/quiz";

const initialState = {
  // 14: {}
  13: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_QUIZ_STEP:
      const { step, value } = action.payload;
      return Object.assign(initialState, { [step]: value });
    case REFRESH_QUIZ_STEP:
      return initialState;
    default:
      return state;
  }
};
