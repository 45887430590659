import '../../index.css';
import React from "react";
import { 
  Slider, 
  Radio, 
  Input, 
  Typography,
  InputNumber, 
  Icon, 
  Tag, 
  Popover,
  Badge
} from "antd";
import { Link } from 'react-router-dom';
import ingredients from '../../data/ingredients';

const { Title, Text } = Typography;
const { CheckableTag } = Tag;

const ContentBody = ({
  title,
  subtitle
}) => (
  <div style={styles.content}>
    <Title level={2} style={styles.question}>{title}</Title>
    <Title level={3} style={styles.title}>{subtitle}</Title>
  </div>
);

const ContentBodyWithSlider = ({
  min,
  max,
  marks,
  onChange,
  title,
  question,
  selected
}) => (
  <div style={styles.content}>
    <Title level={2} style={styles.question}>{question}</Title>

    <div style={styles.body}>
      <Slider
        step={null}
        min={min}
        max={max}
        onChange={onChange}
        marks={
          marks.reduce((obj, i) => {
            obj[i] = { 
              label:
                i === 0 || i === max ?
                  <strong>
                    {i === max && max !== 10 ? `${max}+` : String(i)}
                  </strong>
                  : <></>
            };
            return obj;
          }, {})
        }
        defaultValue={selected ? selected : 0}
        style={{...styles.slider, width: (max + 1) * 100 }}
      />
    </div>
  </div>
);

const ContentBodyWithNumericInput = ({ 
  onChange, 
  title, 
  question,
  placeholder,
  selected,
  max
}) => (
  <div style={styles.content}>
    {/* <Title level={3} style={styles.title}>{title}</Title> */}
    <Title level={2} style={styles.question}>{question}</Title>
    <InputNumber 
      size='large' 
      placeholder={placeholder || 0}
      min={0}
      max={max}
      onChange={value => onChange(value)}
      value={selected && selected !== 0 ? selected : undefined}
      style={{ ...styles.numericInput, width: `${String(selected || 1).length + 10}ch` }}
    />
  </div>
);

const ContentBodyWithTextInput = ({ 
  onChange, 
  title, 
  question,
  placeholder,
  selected
}) => (
  <div style={styles.content}>
    <Title level={3} style={styles.title}>{title}</Title>
    <Title level={2} style={styles.question}>{question}</Title>

    <div style={styles.body}>
      <Input 
        size='large' 
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        style={{ ...styles.textInput, width: `${String(selected || placeholder).length + 1}ch` }}
        defaultValue={selected}
      />
    </div>
  </div>
);

const ContentBodyWithBinaryRadio = ({ onChange, title, question, selected }) => (
  <div style={styles.content}>
    <Title level={2} style={styles.question}>{question}</Title>

    <div style={styles.body}>
      <Radio.Group
        defaultValue={0}
        buttonStyle="solid"
        onChange={e => onChange(e.target.value)}
        style={styles.radioGroup}
        className='stepsRadioGroup'
      >
        <Radio.Button 
          checked={(selected || 0) === 0} 
          value="0"
          style={{ ...styles.radioButton, borderRadius: '50%' }}
          className='stepsRadioButton'
        >
          <div style={styles.radioButtonContent}>
            <Title 
              // underline={(selected || 0) === 0} 
              level={3}
              style={{ color: '#B1736C' }}
            >
              No
            </Title>
          </div>
        </Radio.Button>

        <Radio.Button 
          checked={(selected || 0) === 1} 
          value="1"
          style={{ ...styles.radioButton, borderRadius: '50%' }}
          className='stepsRadioButton'
        >
          <div style={styles.radioButtonContent}>
            <Title 
              // underline={(selected || 0) === 1} 
              level={3}
              style={{ color: '#B1736C' }}
            >
              Yes
            </Title>
          </div>
        </Radio.Button>
      </Radio.Group>
    </div>
  </div>
);

const ContentBodyWithNonBinaryRadio = ({
  onChange,
  title,
  question,
  responses,
  selected,
  quiz
}) => (
  <div style={styles.content}>
    <Title level={2} style={styles.question}>{question}</Title>
  
    <div style={styles.body}>
      <Radio.Group
        defaultValue={0}
        buttonStyle="solid"
        onChange={e => onChange(e.target.value)}
        style={styles.radioGroup}
        className='stepsRadioGroup'
      >
        {responses.map(({ response, symbol }, i) => 
            true
            // !(title === 'Flavor' 
            // && quiz 
            // && quiz[5] == 1 whichever the plant-based one is
            // && !['Chocolate', 'Vanilla'].includes(response))
            && ( <Radio.Button
                checked={(selected || 0) === i} 
                key={i}
                value={String(i)}
                style={styles.radioButton}
                className='stepsRadioButton'
              >
                <div style={styles.radioButtonContent}>
                  {
                    symbol && 
                    <Icon 
                      type={symbol} 
                      style={{ 
                        fontSize: '32px',
                        color: '#B1736C', 
                        marginBottom: '5px' 
                      }}
                    />
                  }
                  <Title 
                    // underline={(selected || 0) === i} 
                    level={3}
                    style={{ color: '#B1736C' }}
                  >
                    {response}
                  </Title>
                </div>
              </Radio.Button>
            )
        )}
      </Radio.Group>
    </div>
  </div>
);

const ContentBodyWithIngredientTags = ({
  onChange,
  title,
  question,
  selected
}) => (
  <div style={styles.content}>
    <Title level={3} style={styles.title}>{title}</Title>
    <Title level={2} style={styles.question}>{question}</Title>

    <div style={styles.body}>
      {
        Object.keys(ingredients).map((key) => {
          const { name, description, color } = ingredients[key];

          return (
            <CheckableTag 
              color={color} 
              checked={selected[key]}
              onChange={(checked) => {
                Object.assign(selected, { [key]: checked });
                return onChange(selected);
              }}
              style={{ margin: '10px' }}
            >
              <Text>{name}</Text>
            </CheckableTag>
          );
        })
      }
    </div>
  </div>
);


const steps = [
  {
    title: "Name",
    content: (
      <ContentBodyWithTextInput
        title="Before we get started, we'd like to get to know you better."
        question="What's your first name?"
        placeholder="Jane"
      />
    )
  },
  {
    title: "Gender",
    content: (
      <ContentBodyWithNonBinaryRadio
        question="Gender?"
        responses={[
          { response: 'Female', symbol: 'woman' },
          { response: 'Male', symbol: 'man' },
          { response: 'Non-binary', symbol: 'info' },
        ]}
      />
    )
  },
  {
    title: "Age",
    content: (
      <ContentBodyWithNumericInput
        question="Age?"
        max={120}
      />
    )
  },
  {
    title: "Weight",
    content: (
      <ContentBodyWithNumericInput
        question="Weight (in lbs.)?"
        max={2000}
      />
    )
  },
  {
    title: "Goals",
    content: (
      <ContentBodyWithNonBinaryRadio
        question="Are you trying to...?"
        responses={[
          { response: 'Lose' },
          { response: 'Maintain' },
          { response: 'Gain' },
        ]}
      />
    )
  },
//   {
//     title: "Base",
//     content: (
//       <ContentBodyWithBinaryRadio
//         question="Do you follow a specifically plant-based diet?"
//       />
//     )
//   },
  {
    title: "Flavor",
    content: (
      <ContentBodyWithNonBinaryRadio
        title="Flavor"
        question="What's your favorite flavor?"
        responses={[
          { response: 'Chocolate' },
          { response: 'Banana' },
          { response: 'Chocolate Peanut Butter' },
          { response: 'Strawberry' },
          { response: 'Vanilla' },
        ]}
      />
    )
  },
  {
    title: "Pomegranate",
    content: (
      <ContentBodyWithSlider
        min={0}
        max={3}
        marks={[...Array(4).keys()]}
        question="How many servings of fruit and vegetables do you eat per day?"
      />
    )
  },
  {
    title: "L-Theanine",
    content: (
      <ContentBodyWithBinaryRadio
        question="Do you often feel anxious and/or have trouble falling asleep?"
      />
    )
  },
  {
    title: "Vitamin D",
    content: (
      <ContentBodyWithSlider
        min={0}
        max={31}
        marks={[...Array(7).fill(0).map((_, i) => i * 5), 31]}
        question="How many minutes per day do you spend in direct sunlight?"
      />
    )
  },
  {
    title: "Biotin",
    content: (
      <ContentBodyWithBinaryRadio
        question="Are you satisfied with the health of your hair, skin, and nails?"
      />
    )
  },
  {
    title: "Magnesium",
    content: (
      <ContentBodyWithSlider
        min={0}
        max={10}
        marks={[...Array(11).keys()]}
        question="On a scale of 1–10, rate your overall happiness."
      />
    )
  },
  {
    title: "Zinc",
    content: (
      <ContentBodyWithBinaryRadio
        question="Over the course of a year, do you frequently get ill (more than two times)?"
      />
    )
  },
  {
    title: "Ginkgo Biloba",
    content: (
      <ContentBodyWithBinaryRadio
        question="Are you often forgetful?"
      />
    )
  },
  // {
  //   title: "Allergies",
  //   content: (
  //     <ContentBodyWithIngredientTags
  //       title="Lastly, do you have any allergies?"
  //       question="Select any of the following ingredients to which you are allergic."
  //     />
  //   )
  // },
  {
    title: "Complete",
    content: (
      <ContentBody 
        title="Thanks!"
        subtitle="Continue to get your recommended mix."
      />
    )
  }
];

const styles = {
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: "auto",
    // marginRight: "auto"
    marginTop: '25px',
  },
  content: {
    marginTop: '25px',
    // height: 200
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  // question: {
  //   color: '#B1736C',
  // },
  // title: {
  //   color: '#E9D3C5'
  // },
  radioGroup: {
    width: '100%',
    // display: 'flex',
    // alignItems: 'space-between',
    // justifyContent: 'center'

  },
  radioButton: {
    backgroundColor: 'rgba(246, 237, 220, 0)',
    width: '175px',
    height: '175px',
    lineHeight: '175px',
    textAlign: 'center',
    margin: '10px',
    paddingTop: 0,
    marginTop: '10px',
    borderColor: '#E9D3C5',
    
    // border: 0,
    // borderColor: 'white'
    
  },
  radioButtonContent: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // paddingTop: '50px',
    verticalAlign: 'middle',
    lineHeight: 'normal',
    // height: '50%',
    display: 'inline-block'

  },
  slider: {
    width: '500px',
    marginTop: '75px'
  },
  numericInput: {
    border: '5px solid #E9D3C5',
    borderRadius: 0,
    backgroundColor: 'transparent',
    // fontWeight: 'bold',
    padding: '7.5px',
    height: '75px',
    color: '#B1736C',
    marginTop: '25px'
  },
  textInput: {
    borderBottom: '5px solid #E9D3C5',
    borderRadius: 0,
    backgroundColor: 'transparent',
    // fontWeight: 'bold',
    padding: '5px',
    height: '75px',
    color: '#B1736C',
    // marginTop: '25px'
  }
};

export default steps;
