import * as firebase from "firebase/app";
import { getUserById, updateUser } from "../../data/DL";

export const UPDATE_USER_DATA_SUCCESS = "[AUTH] Update User Data Success";
export const updateUserDataSuccess = data => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload: data
});

export const UPDATE_USER_DATA_FAIL = "[AUTH] Update User Data Fail";
export const updateUserDataFail = error => ({
  type: UPDATE_USER_DATA_FAIL,
  payload: error
});

export const CREATE_USER_SUCCESS = "[AUTH] Create User Success";
export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user
});

export const LOGIN_USER_FAIL = "[AUTH] Login User Fail";
export const loginUserFail = error => ({
  type: LOGIN_USER_FAIL,
  payload: error
});

export const LOGIN_USER_SUCCESS = "[AUTH] Login User Success";
export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});

export const CREATE_USER_FAIL = "[AUTH] Create User Fail";
export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error
});

export const USER_LOGOUT = "[AUTH] Logout User";
export const logoutUserSuccess = () => ({
  type: USER_LOGOUT
});

export const USER_LOGOUT_FAIL = "[AUTH] Logout User Fail";
export const logoutUserFail = () => ({
  type: USER_LOGOUT_FAIL
});

export const SET_AUTH_LOADING = "[AUTH] Auth Loading";
export const setAuthLoading = () => ({
  type: SET_AUTH_LOADING
});

export const updateUserData = ({ uid, data }) => dispatch => {
  dispatch(setAuthLoading());

  return updateUser(uid, data)
    .then(() => {
      dispatch(updateUserDataSuccess(data));
    })
    .catch(error => {
      dispatch(updateUserDataFail(error));
    });
};

export const signup = ({ email, password }) => dispatch => {
  dispatch(setAuthLoading());

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(auth => {
      const user = { email, uid: auth.user.uid };
      return updateUser(auth.user.uid, { email }).then(() => {
        dispatch(createUserSuccess(user));
      });
    })
    .catch(error => {
      dispatch(createUserFail(error));
    });
};

export const login = ({ email, password }) => dispatch => {
  dispatch(setAuthLoading());

  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(auth => getUserById(auth.user.uid))
    .then(user => {
      dispatch(loginUserSuccess(user));
    })
    .catch(error => {
      dispatch(loginUserFail(error));
    });
};

export const logout = () => dispatch => {
  dispatch(setAuthLoading());

  return firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(logoutUserSuccess());
    })
    .catch(error => {
      dispatch(logoutUserFail(error));
    });
};

export const refetchUser = uid => dispatch => {
  dispatch(setAuthLoading());

  getUserById(uid).then(user => {
    dispatch(loginUserSuccess(user));
  });
};

export const verifyAuth = () => dispatch => {
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      getUserById(user.uid).then(user => {
        dispatch(loginUserSuccess(user));
      });
    } else {
      dispatch(logoutUserSuccess());
    }
  });
};
