import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";

import { 
  Route,
  Switch,
  BrowserRouter
} from "react-router-dom";
import { Layout } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { history, configureStore } from "./store";

import Home from "./components/pages/home";
import Quiz from "./components/pages/quiz";
import Shop from "./components/pages/shop";
import Auth from "./components/pages/auth";
import ProductPage from "./components/pages/product_page";
import OrderPage from "./components/pages/order_page";
import Account from "./components/pages/account";
import CheckoutPage from "./components/pages/checkout";
import About from "./components/pages/about";
import NavBar from "./components/shared/nav";
import Recommendation from "./components/pages/recommendation";

import "./index.css";
import "antd/dist/antd.css";
import FooterBar from "./components/shared/footer";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);

const { Header, Content, Footer } = Layout;

function App() {
  const { store } = configureStore();

  return (
    <BrowserRouter history={history} store={store}>
      <Layout>
        <Header
          style={{ background: "white", padding: 0, margin: 0, height: 80 }}
        >
          <NavBar />
        </Header>

        <Content>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/quiz" component={Quiz} />
            <Route path="/shop" component={Shop} />
            <Route path="/auth" component={Auth} />
            <Route path="/account" component={Account} />
            <Route path="/checkout" component={CheckoutPage} />
            <Route path="/about" component={About} />
            <Route path="/mixes/:id" component={ProductPage} />
            <Route path="/swag/:id" component={ProductPage} />
            <Route path="/orders/:id" component={OrderPage} />
            <Route path="/recommendation" component={Recommendation} />
          </Switch>
        </Content>

        <Footer style={{ background: "white", padding: 0, margin: 0 }}>
          <FooterBar />
        </Footer>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
