import React from "react";
import { Typography } from "antd";
import ProductCard from "../shared/product_card";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/actions/user";
import { getRecommendedMix } from "../../redux/selectors";
import { getMatchingMix } from "../../data/DL";
import Spinner from "../shared/Spinner";
import AuthForm from "./auth";
import { refreshQuiz } from "../../redux/actions/quiz";

const { Title, Text } = Typography;

class Recommendation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mix: undefined,
      error: false
    };
  }

  async componentWillMount() {
    await this.recommend();
  }

  async recommend() {
    const { base, variant, flavor, ingredients } = this.props.rec;

    const mix = await getMatchingMix(
      base,
      variant,
      flavor,
      Object.values(ingredients).map(ingredient => ingredient.name)
    );

    const { quiz } = this.props;

    const data = {
      name: quiz[0],
      gender: quiz[1] === 0 ? 
        "female" 
        : (
            quiz[3] === 1 ? 
              "male" 
              : "non-binary"
          ),
      age: quiz[2],
      weight: quiz[3],
    };

    const { user } = this.props.user;

    if (user && mix) {
      data.recommendation = mix.id;
      data.quizzed = true;
      this.props.updateUserData({ uid: user.uid, data });
    } else {
      // upload data to firebase for analytics
    }

    this.setState({ mix, error: mix === undefined });
    this.props.refreshQuiz();
  }

  render() {
    const { user } = this.props;
    const { mix, error } = this.state;

    return (
      <>
        {mix ? (
          <div className="recommendationBody" style={styles.body}>
            <div className="recommendationContainer" style={styles.container}>
              <div style={styles.text}>
                <Title style={{ color: "#E9D3C5" }}>
                  Welcome to your{" "}
                  <Title
                    underline
                    style={{ display: "inline-block", color: "#B1736C" }}
                  >
                    elemnt.
                  </Title>
                </Title>
                <Text>
                  This unique formula was derived from a combination of our
                  state of the art deep learning nourish+ algorithm and your
                  answers to precisely fit your needs.
                  <br />
                  <br />
                  Today, and for the future.
                </Text>
              </div>
              <ProductCard product={mix} isMix showInfo showCart />
            </div>
            {!user.isLoggedIn &&
              !user.user && (
                <div
                  className="recommendationContainer"
                  style={styles.container}
                >
                  <div style={styles.text}>
                    <Title style={{ color: "#B1736C" }}>Sign up?</Title>
                    <Text strong>
                      Sign up or login to save your recommendation.
                      <br />
                      <br />
                      If you choose, you can also purchase a subscription.
                    </Text>
                  </div>
                  <AuthForm hideBody callback={() => this.recommend()} />
                </div>
              )}
          </div>
        ) : error ? (
          <Title level={3}>An unknown error has occurred.</Title>
        ) : (
          <Spinner />
        )}
      </>
    );
  }
}

const styles = {
  body: {
    // background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    // background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    display: "flex",
    justifyContent: "space-between"
  },
  text: {
    marginBottom: "72px"
  },
  container: {
    // background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    borderRadius: "0px",
    // backgroundColor: "white",
    // margin: "25px 25px 25px 0px",
    // margin: '2.5vh 5vw 2.5vh 5vw',
    // marginRight: "150px",
    // padding: "25px",
    // display: "inline-block",
    marginRight: "75px",
    marginBottom: "75px",
    width: "90%",
    maxWidth: "750px"
  }
};

const mapDispatchToProps = {
  updateUserData,
  refreshQuiz
};

const mapStateToProps = state => ({
  rec: getRecommendedMix(state),
  user: state.user,
  quiz: state.quiz
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recommendation);
