import React from "react";
import { Form, Icon, Input, Button, Typography } from "antd";
import Spinner from "../shared/Spinner";
import "firebase/auth";
import { connect } from "react-redux";
import { login, signup } from "../../redux/actions/user";
import { Redirect } from 'react-router-dom';
const { Title, Text } = Typography;

class AuthForm extends React.Component {
  state = { register: true };

  handleSubmit = e => {
    e.preventDefault();

    const { callback } = this.props;

    return this.props.form.validateFields((err, values) => {
      if (err) {
        return Promise.reject(err);
      }

      const { email, password } = values;

      if (this.state.register) {
        return this.props.signup({ email, password })
          .then(() => {
            if (callback) {
              callback();
            }
          });
      } else {
        return this.props.login({ email, password })
          .then(() => {
            if (callback) {
              callback();
            }
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user, hideBody } = this.props;

    if (user.isLoading) {
      return <Spinner />;
    }

    if (!hideBody && user.isLoggedIn) {
      return <Redirect to='/account' />;
    }

    return (
      <div style={!hideBody ? styles.body : {}}>
        {!hideBody && (
          <Title style={{ color: "#B1736C" }}>
            Welcome to{" "}
            <Title
              underline
              style={{ display: "inline-block", color: "#E9D3C5" }}
            >
              elemnt
            </Title>
          </Title>
        )}
        <Form onSubmit={this.handleSubmit} style={styles.authForm}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your email!" }]
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Text type="danger">{user.error ? user.error : ""}</Text>
          <Form.Item>
            <Button type="link" htmlType="submit" style={styles.authFormButton}>
              <Text style={{ color: "#B1736C" }}>
                {this.state.register ? "Sign up" : "Login"}
              </Text>
            </Button>
          </Form.Item>
          <Button
            type="link"
            onClick={() => this.setState({ register: !this.state.register })}
            style={styles.switchButton}
          >
            Or {this.state.register ? "login!" : "sign up!"}
          </Button>
        </Form>
      </div>
    );
  }
}

const styles = {
  body: {
    // border: '5px solid #B1736C',
    background: "rgb(246,	237,	223, 0.5)", // '#F7EDDD',
    minHeight: "80vh",
    width: "90vw",
    margin: "2.5vh 5vw 2.5vh 5vw",
    padding: "6vw"
  },
  authForm: {
    maxWidth: "500px",
    border: "5px solid #B1736C",
    padding: "25px"
  },
  authFormButton: {
    color: "#B1736C",
    fontWeight: "bold",
    fontSize: 16
  },
  switchButton: {
    color: "#E9D3C5"
  }
};

const mapDispatchToProps = {
  login,
  signup
};

const mapStateToProps = state => ({
  user: state.user,
  quiz: state.quiz
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "auth_form" })(AuthForm));

// ReactDOM.render();
