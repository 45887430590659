import "../../index.css";

import React from "react";
import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";

import Bottle from '../../assets/img/hand_burst.jpeg';
import Group from '../../assets/img/album_cover.jpeg';

const { Title, Text } = Typography;


export default class Home extends React.Component {
  state = {
    names: ["you", "Liam", "Naomi"],
    i: 0
  };

  componentDidMount() {
    const interval = setInterval(() => {
      const { i, names } = this.state;

      if (i < names.length) {
        this.setState({ i: i + 1 });
      } else {
        clearInterval(interval);
      }
    }, 2000);
  }

  render() {
    const { i, names } = this.state;

    return (
      <>
        <div style={styles.hero} className="hero">
          <div style={styles.section}>
            <div className="homeHeader" style={styles.header}>
              <Title style={{ color: "#B1736C", marginBottom: 5 }}>
                Whole meal dietary supplement,
              </Title>
              <Title style={{ color: "#B1736C", marginTop: 5 }}>
                personalized for <u>{names[i % names.length]}</u>.
              </Title>
            </div>

            <Text className="longHomeDescript">
              <strong>elemnt</strong> is pioneering the future of food. 

              <br /><br />
              Each order comes with 10 bottles. Each one of these bottles 
              is an entire meal, personalized to suit your exact body type 
              and its needs. It is packed with vitamins, nutrients, and 
              protein, all while staying conscious of a calorie count 
              proportionate to your dietary goals. Simply add your 
              favorite milk beverage or water and shake.
              <br /><br />
              Our bodies are all unique. Give yours the perfectly
              matched meal that it deserves. Unlock your elemnt.

              {/* <strong>elemnt</strong> is pioneering the future of food. 
              The basic premise is that each order comes with 10 bottles. 
              Each one of these bottles is an entire meal, personalized to 
              suit your exact body type. It is packed with vitamins, 
              nutrients, and protein, all while staying conscious of a 
              proportionate calorie count to support your dietary 
              goals. Simply add your favorite milk beverage or water, 
              and shake. Imagine your body has been deprived of a proper 
              meal your whole life. Now imagine you are living in the future 
              and are able to drink a powder that can not only meet, but 
              exceed your dietary goals. The future is here. 
              That powder is elemnt. 
              <br /><br />
              We are the future of food */}
            </Text>

            <Text className="shortHomeDescript">
              <strong>elemnt</strong> is pioneering the future of food. 

              <br /><br />
              Each order comes with 10 bottles. Each one of these bottles 
              is an entire meal, personalized to suit your exact body type 
              and its needs. It is packed with vitamins, nutrients, and 
              protein, all while staying conscious of a calorie count 
              proportionate to your dietary goals. Simply add your 
              favorite milk beverage or water and shake.
            </Text>
          </div>

          <div style={styles.action}>
            <Title>
              <Link to="/quiz">Take the quiz!</Link>
            </Title>
          </div>
        </div>
        <div className='heroImages' >
          <div style={{ backgroundImage: `url(${Group})` }} className='heroImage1' />
          <div style={{backgroundImage: `url(${Bottle})`}} className='heroImage2' />
        </div>
      </>
    );
  }
}

const styles = {
  hero: {
    // background: "rgb(246,	237,	223, 0.5)", // '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: "85vh",
    width: "90vw",
    margin: "2.5vh 5vw 2.5vh 5vw",
    padding: "6vw"
  },

  header: {
    marginBottom: "25px"
  },

  action: {
    marginTop: "50px"
  },

  section: {
    marginBottom: "50px",
    width: "95%",
    maxWidth: "600px"
  },

  images: {
    padding: 0, 
    minHeight: 0, 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  }
};
