import {
  UNSUBSCRIBE_REQ_BEGIN,
  UNSUBSCRIBE_REQ_FAILED,
  UNSUBSCRIBE_REQ_SUCCESS
} from "../actions/subscription";

const initialState = { isLoading: false, data: null, error: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_REQ_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case UNSUBSCRIBE_REQ_FAILED:
      return { ...state, isLoading: false, data: null, error: action.payload };
    case UNSUBSCRIBE_REQ_BEGIN:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};
