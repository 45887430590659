import React from "react";
import { connect } from "react-redux";
import Spinner from "../shared/Spinner";
import { getMixById, getSwagById } from "../../data/DL";
import { addProductToCart } from "../../redux/actions/cart";
import ProductCard from "../shared/product_card";
import NutritionCard from "../shared/nutrition_card";
import { Typography } from 'antd';

import Hat from '../../assets/img/hat_tub.jpeg';
import Candle from '../../assets/img/candle.jpeg';
import Shirt from '../../assets/img/album_cover.jpeg';

const imageSources = {
  'hat-tub.jpeg': Hat,
  'candle.jpeg': Candle,
  'album_cover.jpeg': Shirt
};

const { Title, Text } = Typography;

class ProductPage extends React.Component {
  state = {
    product: undefined,
    isMix: false,
    error: false
  };

  async componentWillMount() {
    const { id } = this.props.match.params;
    const isMix = this.props.location.pathname.split('/').includes('mixes');
    this.setState({ id, isMix });

    const product = isMix ? await getMixById(id) : await getSwagById(id);
    this.setState({ product, error: product === undefined });
  }

  addToCart() {
    const { id, isMix } = this.state;
    this.props.addProductToCart({ pid: id, isMix });
  }

  render() {
    const { product, isMix, error } = this.state;
    return (
      <div style={styles.body} className="productPageBody">
        <div style={{display: 'block', width: '40vw'}}>
          { isMix && 
            <div className="prodParagraph" style={styles.section}>
              <div style={styles.header}>
                <Title style={{ color: '#E9D3C5' }}>
                  Welcome to your{' '}
                  <Title
                    underline
                    style={{ display: 'inline-block', color: '#B1736C' }}
                  >
                    elemnt.
                  </Title>
                </Title>
              </div>

              <Text>
                {
                  `
                    elemnt is your personalized whole food dietary supplement. 
                    Containing enough calories and nutrients to support a full 
                    meal, elemnt also targets specific gaps in your nutrition 
                    through our customization feature. Although not yet 
                    recommended for every meal, elemnt is the perfect addition 
                    to a balanced diet. 
                  `
                }
              </Text>
            </div>
          }
          <div style= {styles.containerNut} className="containerNutr">
            <div className="prodcard" style={styles.prod}>
              {product ? (
                <ProductCard product={product} isMix={isMix} showCart />
              ) : error ? (
                <h1>An unknown error has occurred.</h1>
              ) : (
                <Spinner />
              )}
            </div>

            <div className="prodnut" style={styles.nutrition}>
              {product && (window.location.href).includes("mixes") ? (
                <NutritionCard product={product} isMix={isMix} showCart />
              ) : error ? (
                <h1>An unknown error has occurred.</h1>
              ) : (
                ""
                )}
              </div>
            </div>
                

        {!isMix && product && <div style={styles.section}>
            <div className="prodDescription" style={styles.header}>
              <Title>Description</Title>
            </div>
            <Text>
              <ul className="descUl" style={{ padding: 5 }}>
                {
                  product.description.split('\\n')
                    .map(item => <li>{item}</li>)
                }
              </ul>
            </Text>
          </div>}
        </div>
        {!isMix && product && (
          <div className="swagphotos" style={{...styles.sideBar, backgroundImage: `url(${imageSources[product.images.page]})`}} />
        )}

        {isMix && (<></>)}
        </div>
    );
  }
}

const styles = {
  body: {
    background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '6vw',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    marginBottom: '25px'
  },
  section: {
    // border: "5px solid #B1736C",
    // background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    // backgroundColor: "white",
    // margin: "50px 50px 0px 0px",
    // margin: "50px",
    marginBottom: '50px',
    // padding: "75px",
    // display: "inline-block",
    width: '90%',
    maxWidth: '750px'
  },
  sideBar: {
    display: 'block',
    height: '70vh',
    width: '35vw',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containerNut: {
    width: '1500px',
    overflow: 'hidden',
    position: 'relative',
    padding: '0',
    margin: '0'
  },
  prod: {
    display: 'inline-block',
    float: 'left',
    marginBottom: '50px'
  },
  nutrition: {
    display: 'inline-block',
    float: 'left',
    marginLeft: '15vh'
  }
};

const mapDispatchToProps = {
  addProductToCart
};

export default connect(
  null,
  mapDispatchToProps
)(ProductPage);
