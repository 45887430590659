import axios from "axios";
import { Mix, Swag } from "./products";
import querystring from "querystring";
const API = process.env.REACT_APP_FIREBASE_API_BASE_URL;

export const set = (route, query = "", data, headers = {}) => {
  const url = API + route + encodeURI(query);
  return axios.post(
    url,
    querystring.stringify({ data: JSON.stringify(data) }),
    { headers }
  );
};

export const get = (route, query = "", headers) => {
  const url = API + route + encodeURI(query);
  return axios.get(url, { headers }).then(response => response.data);
};

const getMatchingMix = (base, variant, flavor, ingredients) => {
  return get(
    "/getMatchingMix",
    `?base=${base}&variant=${variant}&flavor=${flavor}`.concat(
      ingredients.map(ingredient => `&ingredients[]=${ingredient}`).join("")
    )
  )
    .then(data => new Mix(data))
    .catch(error => {
      console.log(error);
      return undefined;
    });
};

const getMixById = id => {
  return get("/getMixById", `?id=${id}`)
    .then(data => new Mix(data))
    .catch(error => {
      console.log(error);
      return undefined;
    });
};

const getSwagById = id => {
  return get("/getSwagById", `?id=${id}`)
    .then(data => new Swag(data))
    .catch(error => {
      console.log(error);
      return undefined;
    });
};

const getAllSwag = () => {
  return get("/getAllSwag")
    .then(data => data.map(item => new Swag(item)))
    .catch(error => {
      console.log(error);
      return undefined;
    });
};

const getOrderById = id => {
  return get("/getOrderById", `?id=${id}`).catch(error => {
    console.log(error);
    return undefined;
  });
};

const getUserById = id => {
  return get("/getUserById", `?uid=${id}`).catch(error => {
    console.log(error);
    return undefined;
  });
};

const updateUser = (id, data) => {
  return set("/updateUser", `?uid=${id}`, data);
};

const checkout = data => {
  return set("/checkout", "", data);
};

const unsubscribe = data => {
  return set("/unsubscribe", "", data);
};

const logAnalytics = (data) => {
  return set('/logAnalytics', '', data);
}

export {
  getMatchingMix,
  getMixById, 
  getSwagById,
  getAllSwag,
  getOrderById,
  getUserById,
  updateUser, 
  checkout,
  unsubscribe,
  logAnalytics
};
