import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from "../actions/cart";
import _ from "lodash";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const { pid, isMix } = action.payload;
      return [...state, { pid, isMix }];
    case REMOVE_FROM_CART:
      const newState = _.without(
        state,
        _.find(state, { pid: action.payload.pid })
      );
      return newState;
    case CLEAR_CART:
      return [];
    default:
      return state;
  }
};
