import "../../index.css";
import React from "react";
import { connect } from "react-redux";
import { Card, Tag, Typography, Icon, Button, Popover, message } from "antd";
import { Link } from "react-router-dom";
import {
  addProductToCart,
  removeProductFromCart
} from "../../redux/actions/cart";
import ingredients from "../../data/ingredients";
import bases from "../../data/bases";

import Hat from '../../assets/img/back-hat.jpeg';
import Candle from '../../assets/img/candle_rock.jpeg';
import Shirt from '../../assets/img/white_girl.jpeg';

const imageSources = {
  'back-hat.jpeg': Hat,
  'candle_rock.jpeg': Candle,
  'white_girl.jpeg': Shirt
};


const { Title, Text } = Typography;

class ProductCard extends React.Component {
  state = {
    visible: false
  };

  async display() {
    this.setState({ visible: true });
  }

  addToCart() {
    const { product, isMix } = this.props;
    this.props.addProductToCart({ pid: product.id, isMix });
    message.success('Added to cart!');
  }

  removeFromCart() {
    const { product, removeProductFromCart } = this.props;
    removeProductFromCart({ pid: product.id });
  }

  render() {
    const {
      product,
      isMix,
      showInfo,
      showCart,
      showRemove,
      showQuantity
    } = this.props;

    return (
      <>
        <Card
          title={
            <>
              <Title
                level={3}
                style={{
                  display: "inline-block",
                  color: "#B1736C",
                  marginTop: "7px"
                }}
              >
                {showQuantity && `x${product.qty ? product.qty : 1} `}
                {isMix ? product.variant : product.name}
              </Title>

              <Tag
                color="#B1736C"
                style={{
                  float: "right",
                  display: "inline-block",
                  marginTop: "12px"
                }}
              >
                <Text style={{ color: "white" }}>{`$${product.price}`}</Text>
              </Tag>

              {
                isMix && 
                  <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-between' 
                    }}
                  >
                    <Text style={{ display: 'inline-block'  }}>
                      {product.flavor === 'Chocolate Peanut Butter' ? 
                        'Choco. Peanut Butter' 
                        : product.flavor
                      }
                    </Text>
                    <Text
                      style={{ 
                        float: 'right', 
                        display: 'inline-block',
                        marginRight: '5px' 
                      }}
                    >
                      ${(product.price / 10).toFixed(2)}/meal
                    </Text>
                  </div>
              }
            </>
          }
          bordered
          style={styles.card}
          actions={[
            showInfo && (
              <Text>
                <Link
                  to={`/${isMix ? "mixes" : "swag"}/${product.id}`}
                  style={styles.button}
                >
                  <Icon type="eye" style={{ paddingRight: 7 }} />
                  View {isMix ? 'nutrition' : 'details'}
                </Link>
              </Text>
            ),
            showCart && (
              <Text>
                <Link onClick={() => this.addToCart()} style={styles.button}>
                  <Icon type="shopping-cart" style={{ paddingRight: 7 }} />
                  Add to cart
                </Link>
              </Text>
            ),
            showRemove && (
              <Text>
                <Link
                  onClick={() => this.removeFromCart()}
                  style={styles.button}
                >
                  <Icon type="minus-circle" style={{ paddingRight: 7 }} />
                  Remove
                </Link>
              </Text>
            )
          ].filter(item => item !== undefined)}
        >
          <div style={styles.content}>
            {isMix && (
              <>
                <Text>
                    {
                      `
                        Our ${product.variant.toLowerCase()}
                        ${
                          product.base !== bases.STANDARD ? 
                            product.base.toLowerCase() 
                            + (
                                product.base === bases.PLANT ? 
                                  '-based'
                                  : ' '
                              )
                            : ' base'
                        } meal with nutrition 
                        essential to your elemnt.
                      `
                    }
                  </Text>
                <br /><br />
                <ul style={{ paddingLeft: 0, marginBottom: "25px" }}>
                  {product.ingredients.map((ingredient, index) => {
                    const info =
                      ingredients[
                        ingredient
                          .toUpperCase()
                          .replace("-", "_")
                          .replace(" ", "_")
                      ];


                    return (
                      <li style={styles.ingredient} key={index}>
                        <Popover
                          content={
                            <Text>{info.description}</Text>
                          }
                          // title="Title"
                          trigger="hover"
                        >
                          <Tag color={"#B1736C"}>
                            <Text style={{ color: "white" }}>{ingredient}</Text>
                          </Tag>
                        </Popover>
                      </li>
                    );
                  })}
                </ul>

                <Text>
                  Hover over each to learn more!
                </Text>
              </>
            )}
            
            {!isMix && (
              <>
              {/* {'url("./assets/img/' + `${product.images.card}")`} */}
                <div style={{...styles.photo, backgroundImage: `url(${imageSources[product.images.card]})`}} />
                {/* <Text>
                  {product.description.split('\\n').join(' · ')}
                </Text>

                <ul style={{ padding: 15, color: '#B1736C' }}>
                  {
                    product.description.split('\\n')
                      .map((descript) => 
                        <li><Text>{descript}</Text></li>
                      )
                  }
                </ul> */}
              </>
            )}
          </div>
        </Card>
      </>
    );
  }
}

const styles = {
  card: {
    width: "300px",
    height: "470px",
    // backgroundColor: '#E9D3C5',
    borderRadius: 0
  },
  ingredient: {
    color: "#B1736C",
    fontWeight: "bold",
    listStyleType: "none",
    marginBottom: "10px"
  },
  photo: {
    width: "245px",
    height: "225px",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    // background: "rgba(246, 237, 220, 0.5)",
    // margin: "0 auto",
    marginBottom: "5px"
  },
  content: {
    height: "250px",
    width: '260px',
    
  },
  button: {
    color: "#B1736C",
    fontSize: "14px",
    fontWeight: 500
  }
};

const mapDispatchToProps = {
  addProductToCart,
  removeProductFromCart
};

export default connect(
  null,
  mapDispatchToProps
)(ProductCard);
