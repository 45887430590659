import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import quiz from "./quiz";
import cart from "./cart";
import user from "./user";
import checkout from "./checkout";
import subscription from "./subscription";

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    quiz,
    cart,
    checkout,
    subscription
  });
