import { createStore, applyMiddleware, compose } from "redux";
import { verifyAuth } from "./redux/actions/user";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers/root";
import { routerMiddleware } from "connected-react-router";
import { createHashHistory, createBrowserHistory } from "history";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage
};

export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, rootReducer(history));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState = {}) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  let persistor = persistStore(store);

  store.dispatch(verifyAuth());
  return { store, persistor };
}
