export default {
    GAIN: {
        calories: {
            val: 530,
            unit: '',
            percent: -1,
            name: "Calories"
        },
        totalfat: {
            val: 13.1,
            unit: 'g',
            percent: 20,
            name: "Total Fat"
        },
        saturatedfat: {
            val: 7.6,
            unit: 'g',
            percent: 38,
            name: "Saturated Fat"
        },
        cholesterol: {
            val: 56,
            unit: 'mg',
            percent: 19,
            name: "Cholesterol"
        },
        totalcarb: {
            val: 81,
            unit: 'g',
            percent: 27,
            name: "Total Carbohydrate"
        },
        dietaryfiber: {
            val: 1,
            unit: 'g',
            percent: 4,
            name: "Dietary Fiber"
        },
        sugars: {
            val: 23,
            unit: 'g',
            percent: -2,
            name: "Sugars"
        },
        protein: {
            val: 24.5,
            unit: 'g',
            percent: 49,
            name: "Protein"
        },
        sodium: {
            val: 259,
            unit: 'mg',
            percent: 10,
            name: "Sodium"
        },
        potassium: {
            val: 870,
            unit: 'mg',
            percent: 24,
            name: "Potassium"
        },
        vitamina: {
            val: 1800,
            unit: 'I.U.',
            percent: 36,
            name: "Vitamin A"
        },
        calcium: {
            val: 550,
            unit: 'mg',
            percent: 55,
            name: "Calcium"
        },
        vitaminc: {
            val: 60,
            unit: 'mg',
            percent: 100,
            name: "Vitamin C"
        },
        vitamine: {
            val: 30,
            unit: 'I.U.',
            percent: 100,
            name: "Vitamin E"
        },
        thiamin: {
            val: 4.8,
            unit: 'mg',
            percent: 320,
            name: "Thiamin"
        },
        riboflavin: {
            val: 4,
            unit: 'mg',
            percent: 235,
            name: "Riboflavin"
        },
        niacin: {
            val: 50,
            unit: 'mg',
            percent: 250,
            name: "Niacin"
        },
        vitaminb6: {
            val: 5,
            unit: 'mg',
            percent: 250,
            name: "Vitamin B6"
        },
        folicacid: {
            val: 400,
            unit: 'mcg',
            percent: 100,
            name: "Folic Acid"
        },
        vitaminb12: {
            val: 10,
            unit: 'mcg',
            percent: 167,
            name: "Vitamin B12"
        },
        biotin: {
            val: 300,
            unit: 'mcg',
            percent: 100,
            name: "Biotin"
        },
        pacid: {
            val: 25,
            unit: 'mg',
            percent: 250,
            name: "Panthothenic Acid"
        },
        iron: {
            val: 8,
            unit: 'mg',
            percent: 44,
            name: "Iron"
        },
        phosphorus: {
            val: 430,
            unit: 'mg',
            percent: 43,
            name: "Phosphorus"
        },
        iodine: {
            val: 150,
            unit: 'mcg',
            percent: 100,
            name: "Iodine"
        },
        zinc: {
            val: 15,
            unit: 'mg',
            percent: 100,
            name: "Zinc"
        },
        selenium: {
            val: 70,
            unit: 'mcg',
            percent: 100,
            name: "Selenium"
        },
        copper: {
            val: 1.6,
            unit: 'mg',
            percent: 80,
            name: "Copper"
        },
        manganese: {
            val: 2,
            unit: 'mg',
            percent: 100,
            name: "Manganese"
        },
        chromium: {
            val: 120,
            unit: 'mcg',
            percent: 100,
            name: "Chromium"
        },
        molybdenum: {
            val: 75,
            unit: 'mcg',
            percent: 100,
            name: "Molybdenum"
        },
        vitamind: {
            val: 5208,
            unit: 'I.U.',
            percent: 1302,
            name: "Vitamin D"
        },
        ginkgo: {
            val: 120,
            unit: 'mg',
            percent: -2,
            name: "Ginkgo Biloba"
        },
        pomegranate: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "Pomegranate"
        },
        ltheanine: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "L-Theanine"
        },
        mag: {
            val: 471,
            unit: 'mg',
            percent: 118,
            name: "Magnesium"
        }
    },
    MAINTAIN: {
        calories: {
            val: 461,
            unit: '',
            percent: -1,
            name: "Calories"
        },
        totalfat: {
            val: 8.4,
            unit: 'g',
            percent: 13,
            name: "Total Fat"
        },
        saturatedfat: {
            val: 5,
            unit: 'g',
            percent: 24,
            name: "Saturated Fat"
        },
        cholesterol: {
            val: 48,
            unit: 'mg',
            percent: 16,
            name: "Cholesterol"
        },
        totalcarb: {
            val: 73,
            unit: 'g',
            percent: 25,
            name: "Total Carbohydrate"
        },
        dietaryfiber: {
            val: 1,
            unit: 'g',
            percent: 4,
            name: "Dietary Fiber"
        },
        sugars: {
            val: 23,
            unit: 'g',
            percent: -2,
            name: "Sugars"
        },
        protein: {
            val: 23,
            unit: 'g',
            percent: 46,
            name: "Protein"
        },
        sodium: {
            val: 264,
            unit: 'mg',
            percent: 11,
            name: "Sodium"
        },
        potassium: {
            val: 859,
            unit: 'mg',
            percent: 24,
            name: "Potassium"
        },
        vitamina: {
            val: 1450,
            unit: 'I.U.',
            percent: 29,
            name: "Vitamin A"
        },
        calcium: {
            val: 570,
            unit: 'mg',
            percent: 57,
            name: "Calcium"
        },
        vitaminc: {
            val: 60,
            unit: 'mg',
            percent: 100,
            name: "Vitamin C"
        },
        vitamine: {
            val: 30,
            unit: 'I.U.',
            percent: 100,
            name: "Vitamin E"
        },
        thiamin: {
            val: 4.8,
            unit: 'mg',
            percent: 320,
            name: "Thiamin"
        },
        riboflavin: {
            val: 4,
            unit: 'mg',
            percent: 235,
            name: "Riboflavin"
        },
        niacin: {
            val: 50,
            unit: 'mg',
            percent: 250,
            name: "Niacin"
        },
        vitaminb6: {
            val: 5,
            unit: 'mg',
            percent: 250,
            name: "Vitamin B6"
        },
        folicacid: {
            val: 400,
            unit: 'mcg',
            percent: 100,
            name: "Folic Acid"
        },
        vitaminb12: {
            val: 10,
            unit: 'mcg',
            percent: 167,
            name: "Vitamin B12"
        },
        biotin: {
            val: 300,
            unit: 'mcg',
            percent: 100,
            name: "Biotin"
        },
        pacid: {
            val: 25,
            unit: 'mg',
            percent: 250,
            name: "Panthothenic Acid"
        },
        iron: {
            val: 8,
            unit: 'mg',
            percent: 44,
            name: "Iron"
        },
        phosphorus: {
            val: 430,
            unit: 'mg',
            percent: 43,
            name: "Phosphorus"
        },
        iodine: {
            val: 150,
            unit: 'mcg',
            percent: 100,
            name: "Iodine"
        },
        zinc: {
            val: 15,
            unit: 'mg',
            percent: 100,
            name: "Zinc"
        },
        selenium: {
            val: 70,
            unit: 'mcg',
            percent: 100,
            name: "Selenium"
        },
        copper: {
            val: 1.6,
            unit: 'mg',
            percent: 80,
            name: "Copper"
        },
        manganese: {
            val: 2,
            unit: 'mg',
            percent: 100,
            name: "Manganese"
        },
        chromium: {
            val: 120,
            unit: 'mcg',
            percent: 100,
            name: "Chromium"
        },
        molybdenum: {
            val: 75,
            unit: 'mcg',
            percent: 100,
            name: "Molybdenum"
        },
        vitamind: {
            val: 5208,
            unit: 'I.U.',
            percent: 1302,
            name: "Vitamin D"
        },
        ginkgo: {
            val: 120,
            unit: 'mg',
            percent: -2,
            name: "Ginkgo Biloba"
        },
        pomegranate: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "Pomegranate"
        },
        ltheanine: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "L-Theanine"
        },
        mag: {
            val: 467,
            unit: 'mg',
            percent: 117,
            name: "Magnesium"
        }
    },
    LOSE: {
        calories: {
            val: 405,
            unit: '',
            percent: -1,
            name: "Calories"
        },
        totalfat: {
            val: 4.5,
            unit: 'g',
            percent: 7,
            name: "Total Fat"
        },
        saturatedfat: {
            val: 2.5,
            unit: 'g',
            percent: 13,
            name: "Saturated Fat"
        },
        cholesterol: {
            val: 34,
            unit: 'mg',
            percent: 12,
            name: "Cholesterol"
        },
        totalcarb: {
            val: 69,
            unit: 'g',
            percent: 23,
            name: "Total Carbohydrate"
        },
        dietaryfiber: {
            val: 1,
            unit: 'g',
            percent: 4,
            name: "Dietary Fiber"
        },
        sugars: {
            val: 24,
            unit: 'g',
            percent: -2,
            name: "Sugars"
        },
        protein: {
            val: 22,
            unit: 'g',
            percent: 44,
            name: "Protein"
        },
        sodium: {
            val: 243,
            unit: 'mg',
            percent: 10,
            name: "Sodium"
        },
        potassium: {
            val: 861,
            unit: 'mg',
            percent: 24,
            name: "Potassium"
        },
        vitamina: {
            val: 1150,
            unit: 'I.U.',
            percent: 36,
            name: "Vitamin A"
        },
        calcium: {
            val: 568,
            unit: 'mg',
            percent: 56,
            name: "Calcium"
        },
        vitaminc: {
            val: 60,
            unit: 'mg',
            percent: 100,
            name: "Vitamin C"
        },
        vitamine: {
            val: 30,
            unit: 'I.U.',
            percent: 100,
            name: "Vitamin E"
        },
        thiamin: {
            val: 4.8,
            unit: 'mg',
            percent: 320,
            name: "Thiamin"
        },
        riboflavin: {
            val: 4,
            unit: 'mg',
            percent: 235,
            name: "Riboflavin"
        },
        niacin: {
            val: 50,
            unit: 'mg',
            percent: 250,
            name: "Niacin"
        },
        vitaminb6: {
            val: 5,
            unit: 'mg',
            percent: 250,
            name: "Vitamin B6"
        },
        folicacid: {
            val: 400,
            unit: 'mcg',
            percent: 100,
            name: "Folic Acid"
        },
        vitaminb12: {
            val: 10,
            unit: 'mcg',
            percent: 167,
            name: "Vitamin B12"
        },
        biotin: {
            val: 300,
            unit: 'mcg',
            percent: 100,
            name: "Biotin"
        },
        pacid: {
            val: 25,
            unit: 'mg',
            percent: 250,
            name: "Panthothenic Acid"
        },
        iron: {
            val: 8,
            unit: 'mg',
            percent: 44,
            name: "Iron"
        },
        phosphorus: {
            val: 430,
            unit: 'mg',
            percent: 43,
            name: "Phosphorus"
        },
        iodine: {
            val: 150,
            unit: 'mcg',
            percent: 100,
            name: "Iodine"
        },
        zinc: {
            val: 15,
            unit: 'mg',
            percent: 100,
            name: "Zinc"
        },
        selenium: {
            val: 70,
            unit: 'mcg',
            percent: 100,
            name: "Selenium"
        },
        copper: {
            val: 1.6,
            unit: 'mg',
            percent: 80,
            name: "Copper"
        },
        manganese: {
            val: 2,
            unit: 'mg',
            percent: 100,
            name: "Manganese"
        },
        chromium: {
            val: 120,
            unit: 'mcg',
            percent: 100,
            name: "Chromium"
        },
        molybdenum: {
            val: 75,
            unit: 'mcg',
            percent: 100,
            name: "Molybdenum"
        },
        vitamind: {
            val: 5208,
            unit: 'I.U.',
            percent: 1302,
            name: "Vitamin D"
        },
        ginkgo: {
            val: 120,
            unit: 'mg',
            percent: -2,
            name: "Ginkgo Biloba"
        },
        pomegranate: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "Pomegranate"
        },
        ltheanine: {
            val: 500,
            unit: 'mg',
            percent: -2,
            name: "L-Theanine"
        },
        mag: {
            val: 464,
            unit: 'mg',
            percent: 117,
            name: "Magnesium"
        }
    }

}