import "../../index.css";

import React from "react";
import { Steps, Button, message, Layout, Typography } from "antd";
import steps from "./steps";
import Recommendation from "./recommendation";
import { connect } from "react-redux";
import { setQuizStep } from "../../redux/actions/quiz";
import { Link } from 'react-router-dom';

const { Step } = Steps;
const { Text } = Typography;

class Quiz extends React.Component {
  state = {
    current: 0,
    value: undefined,
    complete: false
  };

  onChange = value => {
    const { current } = this.state;

    if (isNaN(value)) {
      this.setState({ value });
      this.props.setQuizStep({ step: current, value });
    } else {
      this.setState({ value: Number(value) });
      this.props.setQuizStep({ step: current, value: Number(value) });
    }
  };

  async done() {
    const { listenerCallback } = this.state;
    document.removeEventListener('keydown', listenerCallback, false);

    message.success("Done!");
    this.setState({ complete: true });
  }

  next() {
    const { current, value } = this.state;

    if (current === steps.length - 1) {
      return;
    }

    if (value !== undefined && current !== 14) {
      this.props.setQuizStep({
        step: current,
        value: isNaN(value) ? value : Number(value)
      });
    }

    this.setState({
      current: current + 1,
      value: 0
    });

    window.scroll(0, 0);
  }

  prev() {
    const { current } = this.state;

    if (current === 0) {
      return;
    }

    this.setState({
      current: current - 1,
      value: undefined
    });
  }

  componentDidMount() {

    function defocus() {
      const els = document.getElementsByTagName('input');

      for (let el of els) {
        el.blur();
      }
      
        // .forEach((el) => el.blur());
    }

    const listenerCallback = (evt) => {
      const { current } = this.state;
      
      switch (evt.key) {
        case 'Enter':
          if (current < steps.length - 1) {
            defocus();
            this.next();
          } else {
            defocus();
            this.done();
          }

          return;
        case 'ArrowRight':
          if (current < steps.length - 1) {
            defocus();
            this.next(); 
          } else if (current === steps.length - 1) {
            defocus();
            this.done();
          }

          return;
        case 'ArrowLeft':
          if (current > 0) {
            defocus();
            this.prev(); 
          }

          return;
        default: 
          return;
      }
    };
    
    document.addEventListener('keydown', listenerCallback, false);
    this.setState({ listenerCallback });
  }

  componentWillUnmount() {
    const { listenerCallback } = this.state;
    document.removeEventListener('keydown', listenerCallback, false);
  }

  render() {
    const { current, value } = this.state;
    const { quiz } = this.props;

    return (
      <div style={styles.body} className='quizBackground'>
        {this.state.complete ? (
          <Recommendation />
        ) : (
          <>
            <Steps current={current} progressDot style={styles.stepsBar}>
              {/*  TODO: filter out second step if neither is NOT selected for base question */}
              {steps.map(item => (
                <Step style={styles.step} key={item.title} />
              ))}
            </Steps>

            <div className="steps-content" style={styles.stepsContent}>
              {React.cloneElement(steps[current].content, {
                onChange: this.onChange,
                selected: quiz[current] || value,
                quiz
              })}
            </div>

            <div className="steps-action" style={styles.stepsAction}>
              {current > 0 && (
                <Text>
                  <Link
                    style={styles.navButton}
                    onClick={() => this.prev()}
                  >
                    Prev.
                  </Link>
                </Text>
              )}
              {current < steps.length - 1 && (
                <Text>
                  <Link
                    style={{
                      ...styles.navButton,
                      float: "right",
                      // color: "#B1736C"
                    }}
                    onClick={() => this.next()}
                  >
                    Next
                  </Link>
                </Text>
              )}
              {current === steps.length - 1 && (
              <Text>
                <Link
                  style={{
                    ...styles.navButton,
                    float: "right",
                    // color: "#B1736C"
                  }}
                  onClick={() => this.done()}
                >
                  Done
                </Link>
              </Text>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const styles = {
  body: {
    // background: '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '6vw',
  },

  stepsContent: {
    marginTop: "15px",
    // border: '1px dashed #e9e9e9',
    // borderRadius: '6px',
    // background: '#F7EDDD',
    minHeight: "60vh",
    textAlign: "center",
    // paddingTop: "50px",
    // paddingBottom: "50px",
    marginBottom: "0px",
    // display: 'flex',
    // justifyContent: 'center'
    background: 'transparent'
  },
  stepsAction: {
    marginTop: "24px"
  },
  stepsBar: {},
  step: {},
  navButton: {
    // color: "#E9D3C5",
    fontWeight: "bold",
    fontSize: 18
  }
};

const mapDispatchToProps = {
  setQuizStep
};

const mapStateToProps = state => ({
  user: state.user,
  quiz: state.quiz
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quiz);
