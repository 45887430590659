import '../../index.css';

import React from "react";
import { Layout, Typography } from "antd";
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

export default class About extends React.Component {

  render() {    
    return (
      <div style={styles.body}>
        <div style={styles.section}>
          <div style={styles.header}>
            <Title style={{ color: '#B1736C', marginBottom: 5 }}>
              Quality
            </Title>
          </div>

          <Text>
            Our R&D team has scoured the globe focusing on elemntal properties 
            of organic matter that will enhance your life. By integrating our 
            unique ingredients combined with our state of the art nourish+ 
            algorithm, we leverage technology and nature in everything we do. 
            We derive our passion from our desert roots in sand and powder. 
            With your support, we will continue to research into the elemnts of 
            food in order to create a more sustainable, nutritious future for all.
          </Text>
          
          <div style={styles.action}>
            <Text>
              We are engineering the future of food.&nbsp;
              <Link to='/quiz'>Unlock your elemnt today.</Link>
            </Text>
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.header}>
            <Title style={{ color: '#B1736C', marginBottom: 5 }}>
              Questions
            </Title>
          </div>

          <Text>
            We are dedicated to an excellent quality of service
            for our customers. This includes support via our email, which
            we man 24/7 to provide quick and reliable assistance 
            for all our customers.
          </Text>
          
          <div style={styles.action}>
            <Text>
              If you have any questions or concerns, or have experienced
              any difficulties, do not hesitate to&nbsp;
              <a href='mailto:inmyelemnt@gmail.com'>let us know.</a>
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    padding: '6vw'
  },

  content: {
    // width: '45vw'
  },

  header: {
    marginBottom: '25px'
  },
  
  action: {
    marginTop: '25px'
  },

  section: {
    marginBottom: '75px',
    width: '90%',
    maxWidth: '750px'
  }
};
