import bases from "../data/bases";
import variants from '../data/variants';
import flavors from '../data/flavors';
import ingredients from "../data/ingredients";

export const getRecommendedMix = state => {
  const { quiz } = state;

  let base = bases.STANDARD;
  
//   switch (quiz[5]) {
//     case 0:
//       base = bases.STANDARD;
//       break;
//     case 1:
//       base = bases.PLANT;
//       break;
//     default:
//       break;
//   }

  let variant;

  switch (quiz[4]) {
    case 0:
      variant = variants.LOSE;
      break;
    case 1:
      variant = variants.MAINTAIN;
      break;
    case 2:
      variant = variants.GAIN;
      break;
    default:
      break;
  }

  let flavor;

  switch (quiz[5]) {
    case 0: 
      flavor = flavors.CHOCOLATE;
      break;
    case 1: 
      flavor = flavors.BANANA;
      break;
    case 2: 
      flavor = flavors.CHOCOLATE_PEANUT_BUTTER;
      break;
    case 3:
      flavor = flavors.STRAWBERRY;
      break;
    case 4: 
      flavor = flavors.VANILLA;
      break;
    default:
      break;
  }

  const selectedIngredients = [];
  const allergies = quiz[13];

  if (quiz[6] < 3 && !allergies.POMEGRANATE) {
    selectedIngredients.push(ingredients.POMEGRANATE);
  }

  if (quiz[7] === 1 && !allergies.L_THEANINE) {
    selectedIngredients.push(ingredients.L_THEANINE);
  }

  if (quiz[8] < 31 && !allergies.VITAMIN_D) {
    selectedIngredients.push(ingredients.VITAMIN_D);
  }

  if (quiz[9] === 0 && !allergies.BIOTIN) {
    selectedIngredients.push(ingredients.BIOTIN);
  }

  if (quiz[10] < 9 && !allergies.MAGNESIUM) {
    selectedIngredients.push(ingredients.MAGNESIUM);
  }

  if (quiz[11] === 1 && !allergies.ZINC) {
    selectedIngredients.push(ingredients.ZINC);
  }

  if (quiz[12] === 1 && !allergies.GINKGO_BILOBA) {
    selectedIngredients.push(ingredients.GINKGO_BILOBA);
  }

  selectedIngredients.sort((a, b) => a.priority - b.priority);

  const allIngredients = Object.values(ingredients).sort(
    (a, b) => a.priority - b.priority
  );

  const prioritizedIngredients = selectedIngredients.slice(0, 5);

  if (prioritizedIngredients.length < 5) {
    prioritizedIngredients.push(
      ...allIngredients
        .filter(
          a =>
            prioritizedIngredients.filter(b => b.name === a.name).length === 0
            && !allergies[a.name.toUpperCase().replace('-', '_').replace(' ', '_')]
        )
        .slice(0, 5 - prioritizedIngredients.length)
    );
  }

  return { base, variant, flavor, ingredients: prioritizedIngredients };
};
