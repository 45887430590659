class Mix {
  constructor(data) {
    const { id, price, base, variant, flavor, ingredients, nutrients } = data;

    this.id = id;
    this.price = price;
    this.base = base;
    this.variant = variant;
    this.flavor = flavor;
    this.ingredients = ingredients;
    this.nutrients = nutrients;
  }
}

class Swag {
  constructor(data) {
    const { id, name, description, price, images } = data;

    this.id = id;
    this.name = name;
    this.description = description;
    this.price = price;
    this.images = images;
  }
}

export { Mix, Swag };
