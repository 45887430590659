import { checkout } from "../../data/DL";
import { clearCart } from "./cart";

export const CHARGE_REQ_BEGIN = "[CHECKOUT] Charge begin";
export const setChargeBegin = () => ({
  type: CHARGE_REQ_BEGIN
});

export const CHARGE_REQ_SUCCESS = "[CHECKOUT] Charge success";
export const setChargeSuccess = data => ({
  type: CHARGE_REQ_SUCCESS,
  payload: data
});

export const CHARGE_REQ_FAILED = "[CHECKOUT] Charge failed";
export const setChargeFailed = data => ({
  type: CHARGE_REQ_FAILED,
  payload: data
});

export const sendChargeReq = (token, metadata) => dispatch => {
  dispatch(setChargeBegin());

  return checkout({ token, metadata })
    .then(res => {
      dispatch(clearCart());
      dispatch(setChargeSuccess(res));
    })
    .catch(error => {
      dispatch(setChargeFailed(error));
      return Promise.reject(error);
    });
};
