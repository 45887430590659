import React from "react";
import CheckoutInputs from "./CheckoutInputs";
import { connect } from "react-redux";
import { Elements, injectStripe } from "react-stripe-elements";
import { getMixById, getSwagById, logAnalytics } from "../../data/DL";
import ProductCard from "../shared/product_card";
import { List, Typography, message } from "antd";
import { sendChargeReq } from "../../redux/actions/checkout";
import { updateUserData } from "../../redux/actions/user";
import { Redirect } from 'react-router-dom';
import _ from "lodash";

const { Title, Text } = Typography;

class _CheckoutForm extends React.Component {
  state = {
    complete: false
  };

  onPayClick = (values) => {
    const {
      name,
      company,
      addressLineOne,
      addressLineTwo,
      city,
      region,
      country,
      zip,
      email,
      subscribe
    } = values;

    const address = {
      name,
      company,
      addressLineOne,
      addressLineTwo,
      city,
      region,
      country,
      zip,
      email
    };

    const { cartIds, sendChargeReq, updateUserData, user } = this.props;

    if (this.props.stripe && cartIds.length > 0) {
      this.props.stripe
        .createToken()
        .then(({ token }) =>
            token
              ? sendChargeReq(token.id, {
                  name,
                  cart: cartIds,
                  uid: user ? user.uid : undefined,
                  address,
                  subscribe
                })
              : Promise.reject()
        )
        .then(() => {
          if (user) {
            return updateUserData({ name:name, uid: user.uid, data: { address } });
          } else {
            return Promise.resolve();
          }
        })
        .then(() => {
          message.success("Thank you!");
          this.setState({ complete: true });
        })
        .catch((error) => message.error(error))
        .finally(() => {
          const { quiz } = this.props;

          if (!quiz) {
            return Promise.resolve();
          }
          
          const data = {
            gender: quiz[1] === 0 ? 
              "female" 
              : (
                  quiz[3] === 1 ? 
                    "male" 
                    : "non-binary"
                ),
            age: quiz[2],
            weight: quiz[3],
          };
  
          return logAnalytics(data);
        });
    } else {
      if (cartIds.length === 0) {
        message.error("No items in the cart!");
      } else {
        message.error("An error occurred.");
      }
    }
  };

  render() {
    const { complete } = this.state;

    if (complete) {
      return <Redirect to='/account' />;
    }
    
    return (
      <div style={styles.checkoutForm}>
        <CheckoutInputs showStripe onSubmit={this.onPayClick} />
      </div>
    );
  }
}

const CheckoutForm = injectStripe(_CheckoutForm);

class Checkout extends React.Component {
  state = {
    cart: [],
    cartLoading: false
  };

  async updateCart() {
    const { cartIds } = this.props;

    this.setState({ cartLoading: true });

    const cart = await Promise.all(
      [].concat(
        ...cartIds.map(({ pid, isMix }) => [
          isMix ? getMixById(pid) : getSwagById(pid)
        ])
      )
    );

    const countById = _.countBy(cart.filter(item => item), ({ id }) => id);
    const uniqCart = _.uniqBy(cart.filter(item => item), "id");
    const cartWithCount = _.map(uniqCart, item => ({
      ...item,
      qty: countById[item.id]
    }));

    if (cartWithCount) {
      this.setState({ cart: cartWithCount });
    }

    this.setState({ cartLoading: false });
  }

  async componentWillMount() {
    await this.updateCart();
  }

  render() {
    const { cart, cartLoading } = this.state;
    const { cartIds, checkout } = this.props;

    console.log('hi', checkout.isLoading);

    if (
      !checkout.isLoading
      && cart.reduce((count, { qty }) => count + qty, 0) > cartIds.length
    ) {
      setTimeout(() => window.location.reload(), 1000);
    }

    return (
      <div className='checkoutBody' style={styles.body}>
        <Elements>
          <>
            <div className='checkoutContainer' style={styles.container}>
              <CheckoutForm {...this.props} />
            </div>
            <div className='checkoutContainer' style={styles.container}>
              <Title level={2} style={{ color: "#B1736C" }}>
                Cart
              </Title>
              <List
                loading={cartLoading}
                itemLayout="vertical"
                dataSource={cart}
                renderItem={product => (
                  <List.Item>
                    <ProductCard
                      key={product.id}
                      product={product}
                      isMix={product.base && typeof product.base === "string"}
                      showInfo
                      showQuantity
                      showRemove
                      style={styles.lineItem}
                    />
                  </List.Item>
                )}
              />
            </div>
          </>
        </Elements>
      </div>
    );
  }
}

const styles = {
  body: {
    // height: "75vh",
    // background: '#F7EDDD',
    // border: '5px solid #B1736C',
    minHeight: '85vh',
    width: '90vw',
    margin: '2.5vh 5vw 2.5vh 5vw',
    // padding: '6vw',
    display: "flex",
    justifyContent: "space-around"
  },
  container: {
    // border: "5px solid #B1736C",
    borderRadius: "0px",
    background: 'rgb(246,	237,	223, 0.5)',	// '#F7EDDD',
    // marginRight: '25px',
    // backgroundColor: "white",
    // margin: "50px 50px 0px 0px",
    // margin: "50px",
    padding: "2vw",
    marginRight: '1vw',
    // display: "inline-block",
    width: '100%',
    maxWidth: '500px',
    marginBottom: '50px'
  },
  lineItem: {
    // display: 'inline-block'
  }
};

const mapStateToProps = state => ({
  cartIds: state.cart,
  user: state.user.user,
  quiz: state.quiz,
  checkout: state.checkout
  // cartIds: getProductIds(state)
});

const mapDispatchToProps = {
  sendChargeReq,
  updateUserData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
