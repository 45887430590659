export default {
  POMEGRANATE: {
    name: 'Pomegranate',
    priority: 4,
    description: 'Pomegranate is a rich red fruit loaded with antioxidants, vitamins, potassium, iron, and is a powerful antioxidant that supports DNA integrity and promotes overall cell health.',
    color: 'magenta'
  },
  BIOTIN: {
    name: 'Biotin',
    priority: 5,
    description: 'Biotin is a water-soluble B-vitamin that is important for energy metabolism. In addition, biotin is important for the health of your hair, skin, and nails.',
    color: 'purple'
  },
  L_THEANINE: {
    name: 'L-Theanine',
    priority: 1,
    description: 'L-Theanine is an amino acid found to be relaxing, but not sedating agent, and has been found to help process stress and to improve attention. It has also been found to aid in sleep quality.',
    color: 'orange'
  },
  GINKGO_BILOBA: {
    name: 'Ginkgo Biloba',
    priority: 2,
    description: 'Ginkgo Biloba is a plant used as a treatment for blood disorders and memory problems, enhancement of cardiovascular function and to improve eye health.',
    color: 'green'
  },
  MAGNESIUM: {
    name: 'Magnesium',
    priority: 3,
    description: 'Magnesium is a mineral that plays a critical role in brain function and mood, and low levels are linked to an increased risk of depression. It is also critically involved in more than 600 reactions in your body.',
    color: 'cyan'
  },
  VITAMIN_D: {
    name: 'Vitamin D',
    priority: 6,
    description: 'Vitamin D is important for a number of reasons, including maintaining healthy bones and teeth; it may also protect against a range of conditions such as cancer, type 1 diabetes, and multiple sclerosis.',
    color: 'gold'
  },
  ZINC: {
    name: 'Zinc',
    priority: 7,
    description: 'Zinc is a trace element that is necessary for a healthy immune system as well as proper growth and development. It also helps to stimulate the activity of at least 100 different enzymes in the body.',
    color: '#bac4c8'
  }
};
