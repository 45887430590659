import "../../index.css";

import React from "react";
import { Form, Input, Checkbox } from "@jbuschke/formik-antd";
import { Button, Icon, Typography } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { CardElement } from "react-stripe-elements";

const { Title, Text } = Typography;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Full name is required"),
  addressLineOne: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("Region is required"),
  // country: Yup.string().required("Country is required"),
  zip: Yup.number().required("Zipcode is required"),
  email: Yup.string().email().required("Email is required")
});

class CheckoutInputs extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props.user;
    this.initialValues = (user && user.address) || {};
    this.initialValues.country = 'USA';
  }

  onRenderProps = ({
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting
  }) => {
    const { checkout, showStripe, user } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Title level={2} style={styles.header}>
          Shipping Address
        </Title>

        <Form.Item name="name">
          <Input
            style={styles.formInput}
            prefix={<Icon type="user" style={styles.icon} />}
            placeholder="Name"
            name="name"
            allowClear
          />
        </Form.Item>

        <Form.Item name="company">
          <Input
            style={styles.formInput}
            prefix={<Icon type="team" style={styles.icon} />}
            placeholder="Company (optional)"
            name="company"
            allowClear
          />
        </Form.Item>

        <Form.Item name="addressLineOne">
          <Input
            style={styles.formInput}
            prefix={<Icon type="home" style={styles.icon} />}
            placeholder="Address Line 1"
            name="addressLineOne"
          />
        </Form.Item>

        <Form.Item name="addressLineTwo">
          <Input
            style={styles.formInput}
            prefix={<Icon type="home" style={styles.icon} />}
            placeholder="Address Line 2 (optional)"
            name="addressLineTwo"
          />
        </Form.Item>

        <Form.Item name="city">
          <Input
            style={styles.formInput}
            prefix={<Icon type="build" style={styles.icon} />}
            placeholder="City"
            name="city"
          />
        </Form.Item>

        <Form.Item name="region">
          <Input
            style={styles.formInput}
            prefix={<Icon type="idcard" style={styles.icon} />}
            placeholder="State/Province/Region"
            name="region"
          />
        </Form.Item>

        <Form.Item name="country">
          <Input
            style={styles.formInput}
            prefix={<Icon type="audit" style={styles.icon} />}
            placeholder="Country"
            name="country"
            disabled
            value="USA"
          />
        </Form.Item>

        <Form.Item name="zip">
          <Input
            style={styles.formInput}
            prefix={<Icon type="number" style={styles.icon} />}
            placeholder="ZIP code"
            name="zip"
          />
        </Form.Item>

        <Form.Item name="email">
          <Input
            style={styles.formInput}
            prefix={<Icon type="mail" style={styles.icon} />}
            placeholder="Email"
            name="email"
          />
        </Form.Item>

        {showStripe && user.isLoggedIn && (
          <Form.Item name="subscribe">
            <Checkbox name="subscribe">
              <Text>
                I want to subscribe monthly to the mix(es) in my cart
              </Text>
            </Checkbox>
          </Form.Item>
        )}

        <div style={styles.section}>
          {showStripe && (
            <>
              <Title level={2} style={styles.header}>
                Payment
              </Title>
              <CardElement style={styles.card} />
            </>
          )}

          <Button
            loading={checkout.isLoading}
            type="link"
            htmlType="submit"
            style={styles.payButtonStyle}
          >
            <Text style={{ color: "#B1736C" }}>
              {showStripe ? "Pay" : "Save"}
            </Text>
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    const { onSubmit } = this.props;

    return (
      <div style={styles.checkoutInputStyle}>
        <Formik
          onSubmit={onSubmit}
          initialValues={this.initialValues}
          render={this.onRenderProps}
          validationSchema={validationSchema}
        />
      </div>
    );
  }
}

const styles = {
  checkoutInputStyle: {
    width: "100%"
  },
  payButtonStyle: {
    marginTop: "25px",
    marginLeft: "-15px",
    color: "#B1736C"
  },
  section: {
    marginTop: "25px"
  },
  icon: {
    color: "#E9D3C5"
  },
  card: {
    base: {
      fontSize: "18px",
      placeholderColor: "black",
      placeholder: {
        color: "black"
      }
    },
    placeholder: {
      color: "black"
    }
  },
  header: {
    marginBottom: "25px",
    color: "#B1736C"
  }
};

const mapStateToProps = state => ({
  user: state.user,
  checkout: state.checkout
});

export default connect(mapStateToProps)(CheckoutInputs);