import '../../index.css';
import React from "react";
import { Badge, Icon, Button, Typography } from "antd";
import { connect } from "react-redux";
import "firebase/auth";
import { withRouter, Link } from "react-router-dom";

const { Text } = Typography;

class NavBar extends React.Component {
  render() {
    const { cartSize, user } = this.props;

    return (
      <>
        <div className="navHome" style={styles.navHome}>
          <Link to='/' className='navItem' style={styles.navItem}>
            elemnt
          </Link>
        </div>
        
        <div className="navBody" style={styles.navBody}>
          <div className='navLeft' style={styles.navLeft}>
            <Text className='navItem' style={styles.navItem}>
              <Link to='/quiz' className='navItem' style={styles.navItem}>QUIZ</Link>
            </Text>

            <Text className='navItem' style={styles.navItem}>
              <Link to='/shop' className='navItem' style={styles.navItem}>SHOP</Link>
            </Text>
          </div>

          <div className="navCenter" style={styles.navCenter}>
            <Link to='/' className='navItem' style={styles.navItem}>
              elemnt
            </Link>
          </div>

          <div className="navRight" style={styles.navRight}>
            <Text className='navItem' style={styles.navItem}>
              <Link to='/about' className='navItem' style={styles.navItem}>ABOUT</Link>
            </Text>

            {user.isLoggedIn && (
              <Text className='navItem' style={styles.navItem}>
                <Link to='/account' className='navItem' style={styles.navItem}>ACCOUNT</Link>
              </Text>
            )}
            {!user.isLoggedIn && (
              <Text className='navItem' style={styles.navItem}>
              <Link to='/auth' className='navItem' style={styles.navItem}>LOGIN / SIGN UP</Link>
            </Text>
            )}

            <Link to='/checkout' className='navItem' style={styles.navItem}>
              <Badge 
                count={cartSize} 
                style={{ backgroundColor: '#B1736C', color: 'white' }}
              >
                <Icon 
                  type="shopping-cart" 
                  width="3em" 
                  height="3em" 
                  // style={{ marginRight: '5px' }}
                />
              </Badge>
              {/* CART */}
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  navBody: {
    fontSize: 18,
    border: 0,
    height: '100%',
    textAlign: 'center',
    width: '90vw',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
    // padding: 0
  },

  navItem: {
    color: '#B1736C',
    marginRight: '15px',
    height: '25px'
  },

  navCenter: {
    position: 'absolute',
    top: '25px',
    left: '47.9%',
    // textAlign: 'center',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    color: '#B1736C',
    // display: 'inline-block',
    fontWeight: 700,
    fontSize: '16px'
  },

  navHome: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#B1736C',
    fontWeight: 700,
    // fontSize: '16px',
    textAlign: 'center',
    width: '90vw',
    margin: '0 auto',
    marginBottom: '10px'
  },

  navLeft: {
    // float: 'left',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    
    // marginTop: '15px'
  },

  navRight: {
    // float: 'right',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    // marginTop: '15px'
  }

}

// const navBarWithRouter = withRouter(NavBar);

const mapStateToProps = state => ({
  cartSize: state.cart.length,
  user: state.user
});

export default connect(mapStateToProps)(NavBar); //(navBarWithRouter);
