import {
  CHARGE_REQ_BEGIN,
  CHARGE_REQ_FAILED,
  CHARGE_REQ_SUCCESS
} from "../actions/checkout";

const initialState = { isLoading: false, data: null, error: null };
export default (state = initialState, action) => {
  switch (action.type) {
    case CHARGE_REQ_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case CHARGE_REQ_FAILED:
      return { ...state, isLoading: false, data: null, error: action.payload };
    case CHARGE_REQ_BEGIN:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};
